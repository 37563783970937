import React, {useContext, useEffect, useState} from 'react';
import AuthenticatedLayout from "../../components/Layout/AuthenticatedLayout/AuthenticatedLayout";
import {useParams} from "react-router-dom";
import {AuthContext} from "../../shared/context/AuthContext";
import {useData} from "../../shared/util/custom-hooks/use-data";
import {UserService} from "../../shared/services/http-services/user.service";
import Loading from "../Loading/Loading";
import {DataStatus} from "../../shared/enums/data-status.enum";
import {QuickFilterType} from "../../shared/enums/quick-filter-type.enum";
import QuickFilters from "../../components/ProblemIssue/QuickFilters/QuickFilters";
import ProblemIssues from "../../components/ProblemIssue/ProblemIssues/ProblemIssues";
import Card from "../../components/Card/Card";
import QuickFilter from "../../shared/data/models/quick-filter.model";
import Info from "../../components/InfoSection/Info/Info";
import "./Profile.css";
import Button from "../../components/Button/Button";
import {ButtonType} from "../../shared/enums/button-type.enum";
import {Theme} from "../../shared/enums/theme.enum";
import Style from "../../shared/data/models/style.model";
import {Appearance} from "../../shared/enums/appearance.enum";
import {IconType} from "../../shared/enums/icon-type.enum";
import PageException from "../../components/Errors/PageException/PageException";

function Profile() {
    const authContext = useContext(AuthContext);
    const {id} = useParams();

    // Disabled
    const [disabled, setDisabled] = useState(true);

    // QuickFilter
    const [quickFilter, setQuickFilter] = useState(new QuickFilter(QuickFilterType.REPORTEDBY, id));

    // Skip load
    const [skipLoad, setSkipLoad] = useState(authContext.user.id === id);

    // Reset when id changes
    useEffect(() => {
        setQuickFilter(new QuickFilter(QuickFilterType.REPORTEDBY, id));
        setSkipLoad(authContext.user.id === id);
    }, [id]);

    // Load data
    const userService = new UserService(authContext);
    const [restUser, dataStatus, reload, , exception] = useData(() => userService.profile(id), {}, [skipLoad], skipLoad);

    // Set correct user
    let user;
    if (skipLoad) {
        user = authContext.user;
    } else {
        user = restUser;
    }

    // Still loading
    if (dataStatus !== DataStatus.DONE && dataStatus !== DataStatus.ERROR) {
        return <Loading/>;
    }

    if (dataStatus === DataStatus.ERROR) {
        return (
            <AuthenticatedLayout title={"Details"} backTo="/">
                <PageException dataStatus={dataStatus}
                               query={async () => {
                                   await reload();
                               }}
                               itemName={`Profiel van ${id}`}
                               exception={exception}/>
            </AuthenticatedLayout>
        );
    }

    // Content
    return (
        <AuthenticatedLayout title={skipLoad ? "Mijn profiel" : "Profiel van " + user.fullName}
                             pageButtons={
                                 skipLoad ? (
                                     <Button icon={IconType.PENCIL} btnType={ButtonType.LINK}
                                             style={new Style(Theme.PRIMARY, Appearance.OUTLINE)}
                                             args={{to: "/account"}}>
                                         Gegevens wijzigen
                                     </Button>
                                 ) : null
                             }
                             asideHeader={
                                 <h2>Gegevens</h2>
                             }
                             aside={
                                 <Card>
                                     <Info label="Shortname">{user.id}</Info>
                                     <Info label="Naam">{user.fullName}</Info>
                                     <Info label="E-mail">{user.email}</Info>
                                 </Card>
                             }
                             contentHeader={
                                 <div className="profile-content-header">
                                     <h2>Activiteit</h2>
                                     <QuickFilters quickFilter={[quickFilter, setQuickFilter]}
                                                   disabled={disabled}
                                                   quickFilters={[QuickFilterType.REPORTEDBY, QuickFilterType.ASSIGNEDTO, QuickFilterType.RESOLVEDBY]}/>
                                 </div>
                             }>
            <section>
                <ProblemIssues interactive={false}
                               disabled={[disabled, setDisabled]}
                               quickFilter={quickFilter}/>
            </section>
        </AuthenticatedLayout>
    );
}

export default Profile
