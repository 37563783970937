import React from 'react';
import "./Info.css";

function Info(
    {
        label,
        children
    }
) {
    if (!children) {
        return null;
    }

    return (
        <div className="info-item">
            <div className="info-label">{label}</div>
            <div>{children}</div>
        </div>
    );
}

export default Info;
