import {HttpService} from "./http.service";
import {HttpMethod} from "../../enums/http-method.enum";
import DescriptorSerializer from "../../data/serializers/descriptor.serializer";

export class StoreService extends HttpService {

    constructor(authContext) {
        super(authContext, "Stores", new DescriptorSerializer());
    }

    async stores(company) {
        const response = await this.request(company, HttpMethod.GET);

        if (response) {
            try {
                return this.serializer.fromJsonArray(response.data);
            } catch (e) {
                // Do nothing
            }
        }

        return [];
    }
}
