import React, {useEffect, useState} from 'react';

function DataListInput(
    {
        className,
        nameAttribute,
        idAttribute,
        disabled,
        state: [state, stateSetter],
        options,
        forceSetOption = false,
        required = false,
        maxlength = null,
        pattern = null,
        patternMessage = null,
    }
) {
    const [selectedOption, setSelectedOption] = useState("");

    // When the state changes, the selected option should be updated.
    useEffect(() => {
        if (!state && state !== 0) {
            setSelectedOption("");
            return;
        }

        const option = options.filter(option => option.value.toString().toUpperCase() === state.toString().toUpperCase());
        if (option.length !== 0) {
            setSelectedOption(option[0].label);
        } else if (forceSetOption) {
            setSelectedOption(state);
        }
    }, [state, options]);

    const datalistOptions = options.map(
        ({value, label}) =>
            <option
                key={value ?? crypto.randomUUID()}
                value={label}>
                {value}
            </option>
    );

    function onKeyDown(e) {
        if (e.key === "Enter") {
            onBlur();
        }
    }

    function onChange(e) {
        const value = e.target.value;

        // On click on the datalist option, the value is directly set to the value of the option.
        if (options && options.length > 0) {
            const option = options.filter(option => option.label.toString() === value);

            if (option.length !== 0) {
                stateSetter(option[0].value);
            }
        }

        setSelectedOption(value);
    }

    function onBlur() {
        if (options && options.length > 0) {
            const optionEqualsLabel = options.filter(option => option.label.toString().toUpperCase() === selectedOption.toUpperCase());
            const optionEqualsValue = options.filter(option => option.value.toString().toUpperCase() === selectedOption.toUpperCase());

            const optionIncludesLabel = options.filter(option => option.label.toString().toUpperCase().includes(selectedOption.toUpperCase()));
            const optionIncludesValue = options.filter(option => option.value.toString().toUpperCase().includes(selectedOption.toUpperCase()));

            if (optionIncludesLabel.length !== 0 && selectedOption.length > 0) {
                let option;
                if (optionEqualsLabel.length === 1) {
                    option = optionEqualsLabel[0];
                } else {
                    option = optionIncludesLabel[0];
                }

                stateSetter(option.value);
                setSelectedOption(option.label);
            } else if (optionIncludesValue.length !== 0 && selectedOption.length > 0) {
                let option;
                if (optionEqualsValue.length === 1) {
                    option = optionEqualsValue[0];
                } else {
                    option = optionIncludesValue[0];
                }

                stateSetter(option.value);
                setSelectedOption(option.label);
            } else {
                stateSetter("");
                setSelectedOption("");
            }
        } else {
            stateSetter("");
            setSelectedOption("");
        }
    }

    return (
        <>
            <input
                className={className}
                name={nameAttribute}
                value={selectedOption}
                disabled={disabled}
                id={idAttribute}
                list={idAttribute + "-list"}
                onBlur={onBlur}
                onKeyDown={onKeyDown}
                onChange={onChange}
                required={required}
                maxLength={maxlength}
                pattern={pattern}
                title={patternMessage}
            />

            <datalist id={idAttribute + "-list"}>
                {datalistOptions}
            </datalist>
        </>
    )
}

export default DataListInput
