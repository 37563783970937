import ProblemIssue from "../models/problem-issue/problem-issue.model";
import DescriptorSerializer from "./descriptor.serializer";
import ActivitySerializer from "./activity.serializer";
import Priority from "../models/priority.model";
import Status from "../models/status.model";
import DateFacade from "../models/date-facade.model";
import {ActivityType} from "../../enums/activity-type.enum";
import Activity from "../models/activity.model";
import {DescriptionType} from "../../enums/description-type.enum";

export default class ProblemIssueSerializer extends DescriptorSerializer {
    fromJsonArray(jsonArray) {
        try {
            if (jsonArray.length > 0) {
                return jsonArray.map(json => this.fromJson(json));
            }
        } catch (e) {
            // Do nothing
        }

        return [];
    }

    fromJson(json) {
        const problemIssue = new ProblemIssue(super.fromJson(json));
        problemIssue.company = json.company;
        problemIssue.store = json.store;
        problemIssue.equipment = json.equipment;
        problemIssue.application = json.application;
        problemIssue.title = json.title;
        problemIssue.solution = json.solutionDescription;
        problemIssue.siteCommunication = json.siteCommunication;
        problemIssue.externalId = json.externalId;
        problemIssue.openedOn = new DateFacade(json.openedOn);
        problemIssue.openedBy = json.openedBy;
        problemIssue.allocatedTo = json.allocatedTo;
        problemIssue.resolvedOn = new DateFacade(json.resolvedOn);
        problemIssue.resolvedBy = json.resolvedBy;
        problemIssue.resolveTime = json.resolveTime;
        problemIssue.status = new Status(json.status);
        problemIssue.billed = json.billed;
        problemIssue.mutatedOn = new DateFacade(json.mutatedOn);
        problemIssue.mutatedBy = json.mutatedBy;
        problemIssue.priority = new Priority(new DescriptorSerializer().fromJson(json.priority));

        problemIssue.activities = new ActivitySerializer().fromJsonArray(json.comments);
        problemIssue.activities = this.#addUpdates(problemIssue.activities, problemIssue);

        problemIssue.descriptionTypes = this.#getDescriptionTypes(problemIssue);

        return problemIssue;
    }

    toJson(problemIssue) {
        let json = super.toJson(problemIssue);
        json.company = problemIssue.company;
        json.store = problemIssue.store;
        json.equipment = problemIssue.equipment;
        json.application = problemIssue.application;
        json.title = problemIssue.title;
        json.solutionDescription = problemIssue.solution;
        json.siteCommunication = problemIssue.siteCommunication;
        json.externalId = problemIssue.externalId;
        json.openedOn = problemIssue.openedOn?.toString();
        json.openedBy = problemIssue.openedBy;
        json.allocatedTo = problemIssue.allocatedTo;
        json.resolvedOn = problemIssue.resolvedOn?.toString();
        json.resolvedBy = problemIssue.resolvedBy;
        json.resolveTime = problemIssue.resolveTime;
        json.status = problemIssue.status.problemIssueStatus;
        json.billed = problemIssue.billed;
        json.mutatedOn = problemIssue.mutatedOn?.toString();
        json.mutatedBy = problemIssue.mutatedBy;
        json.priority = new DescriptorSerializer().toJson(problemIssue.priority);
        json.comments = new ActivitySerializer().toJsonArray(problemIssue.activities);

        return json;
    }

    #addUpdates(activities, problemIssue) {
        if (problemIssue.openedOn && problemIssue.openedOn.hasDate() && problemIssue.openedBy && problemIssue.openedBy.id) {
            let OpenedActivity = new Activity();
            OpenedActivity.dateTime = problemIssue.openedOn;
            OpenedActivity.user = problemIssue.openedBy;
            OpenedActivity.message = "Problem issue geopend";
            OpenedActivity.type = ActivityType.UPDATE;

            activities.push(OpenedActivity);
        }

        if (problemIssue.resolvedOn && problemIssue.resolvedOn.hasDate() && problemIssue.resolvedBy && problemIssue.resolvedBy.id) {
            let ResolvedActivity = new Activity();
            ResolvedActivity.dateTime = problemIssue.resolvedOn;
            ResolvedActivity.user = problemIssue.resolvedBy;
            ResolvedActivity.message = "Problem issue gesloten";
            ResolvedActivity.type = ActivityType.UPDATE;

            activities.push(ResolvedActivity);
        }

        if (problemIssue.mutatedOn && problemIssue.mutatedOn.hasDate() && problemIssue.mutatedBy && problemIssue.mutatedBy.id) {
            let MutatedActivity = new Activity();
            MutatedActivity.dateTime = problemIssue.mutatedOn;
            MutatedActivity.user = problemIssue.mutatedBy;
            MutatedActivity.message = "Laatste wijziging problem issue";
            MutatedActivity.type = ActivityType.UPDATE;

            activities.push(MutatedActivity);
        }

        // order activities by datetime property
        return activities.sort((a, b) => {
            return new Date(a.dateTime.epoch()) - new Date(b.dateTime.epoch());
        });
    }

    #getDescriptionTypes(problemIssue) {
        const descriptionTypes = [];

        if (problemIssue.description) {
            descriptionTypes.push(DescriptionType.REGULAR);
        }

        if (problemIssue.solution) {
            descriptionTypes.push(DescriptionType.SOLUTION);
        }

        if (problemIssue.siteCommunication) {
            descriptionTypes.push(DescriptionType.SITE_COMMUNICATION);
        }

        return descriptionTypes;
    }
}
