import Identifier from "../models/identifier.model";

export default class IdentifierSerializer {
    fromJson(json) {
        const identifier = new Identifier();
        identifier.id = json.id;

        return identifier;
    }

    fromJsonArray(jsonArray) {
        try {
            if (jsonArray && jsonArray.length > 0) {
                return jsonArray.map(json => this.fromJson(json));
            }
        } catch (e) {
            // Do nothing
        }

        return [];
    }

    toJson(identifier) {
        return {
            id: identifier.id
        };
    }

    toJsonArray(identifiers) {
        try {
            if (identifiers && identifiers.length > 0) {
                return identifiers.map(identifier => this.toJson(identifier));
            }
        } catch (e) {
            // Do nothing
        }

        return [];
    }
}
