import React, {useEffect} from 'react';
import {clearHistory} from "../../../shared/util/history";

function Layout(
    {
        children,
    }
) {
    useEffect(() => {
        // Clear state given through the page via history
        // So it is not used again on reload
        clearHistory();
    }, []);

    return (
        <>
            {children}
        </>
    )
}

export default Layout
