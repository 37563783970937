import User from "../../models/user/user.model";
import UserReducedSerializer from "./user-reduced.serializer";

export default class UserSerializer extends UserReducedSerializer {
    fromJson(json) {
        const user = new User(super.fromJson(json));
        user.firstName = json.firstName;
        user.lastName = json.lastName;
        user.email = json.email;

        return user;
    }

    toJson(user) {
        let json = super.toJson(user);
        json.firstName = user.firstName;
        json.lastName = user.lastName;
        json.email = user.email;

        return json;
    }
}
