import Descriptor from "../descriptor.model.js";

export default class ProblemIssue extends Descriptor {
    company;
    store;
    equipment;
    application;
    title;
    solution;
    siteCommunication;
    externalId;
    openedOn;
    openedBy;
    allocatedTo;
    resolvedOn;
    resolvedBy;
    resolveTime;
    status;
    billed;
    mutatedOn;
    mutatedBy;
    priority;
    activities;
    descriptionTypes;
}
