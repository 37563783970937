import Input from "../Input/Input";
import Info from "../../InfoSection/Info/Info";
import Form from "../Form";
import {useContext, useEffect, useState} from "react";
import {AuthContext} from "../../../shared/context/AuthContext";
import {AccountService} from "../../../shared/services/http-services/account.service";
import Button from "../../Button/Button";
import {IconType} from "../../../shared/enums/icon-type.enum";
import {ButtonType} from "../../../shared/enums/button-type.enum";
import Style from "../../../shared/data/models/style.model";
import {Appearance} from "../../../shared/enums/appearance.enum";
import {Theme} from "../../../shared/enums/theme.enum";

function AccountForm(
    {
        showLogout = false,
        onSuccessfulUpdate
    }
) {
    const authContext = useContext(AuthContext);
    const {user, roles, isAdmin, isDeveloper, refreshAccount} = authContext;
    const accountService = new AccountService(authContext);

    const [firstName, setFirstName] = useState(user.firstName);
    const [lastName, setLastName] = useState(user.lastName);
    const [email, setEmail] = useState(user.email);

    useEffect(() => {
        setFirstName(user.firstName);
        setLastName(user.lastName);
        setEmail(user.email);
    }, [user]);

    async function updateUser() {
        const success = await accountService.update(firstName, lastName, email);
        if (success) {
            await refreshAccount();

            if (onSuccessfulUpdate) {
                onSuccessfulUpdate();
            }
        }
    }

    return (
        <Form buttonText="Opslaan" handleSubmit={updateUser} useLoader={true}
              extraButtons={
                  showLogout ? (
                      <Button title="Uitloggen" icon={IconType.RIGHT_FROM_BRACKET} btnType={ButtonType.LINK}
                              style={new Style(Theme.DARK, Appearance.OUTLINE)} args={{to: "/logout"}}/>
                  ) : null
              }
              inputs={
                  [
                      <Input key="firstName"
                             nameAttribute="firstName"
                             labelText="Voornaam"
                             state={[firstName, setFirstName]}
                             required={true}
                             maxlength={50}/>,
                      <Input key="lastName"
                             nameAttribute="lastName"
                             labelText="Achternaam"
                             state={[lastName, setLastName]}
                             required={true}
                             maxlength={50}/>,
                      <Input key="email"
                             nameAttribute="email"
                             inputType="email"
                             labelText="E-mail"
                             state={[email, setEmail]}
                             required={true}
                             maxlength={100}/>,
                      <>
                          {
                              !showLogout && (isAdmin || isDeveloper) ? (
                                  <Info key="userroles" label="Gebruikersrollen">
                                      {roles.map(role => role.toLowerCase()).join(", ")}
                                  </Info>
                              ) : null
                          }
                      </>
                  ]
              }/>
    );
}

export default AccountForm;
