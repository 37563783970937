import UnauthenticatedLayout from "../../../components/Layout/UnauthenticatedLayout/UnauthenticatedLayout";
import AccountForm from "../../../components/Form/Account/AccountForm";
import {useLocation, useNavigate} from "react-router-dom";
import {useContext} from "react";
import {SuccessContext} from "../../../shared/context/SuccessContext";

function AccountComplete() {
    const {showSuccess} = useContext(SuccessContext);
    const location = useLocation();
    const navigate = useNavigate();

    function redirect() {
        if (location.state && location.state.redirect) {
            navigate(location.state.redirect);
        } else {
            navigate('/');
        }

        showSuccess("Gegevens zijn aangevuld!");
    }

    return (
        <UnauthenticatedLayout title="Gegevens aanvullen">
            <AccountForm showLogout={true} onSuccessfulUpdate={redirect}/>
        </UnauthenticatedLayout>
    );
}

export default AccountComplete;
