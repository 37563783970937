import React from 'react';
import Pill from "../../Pill/Pill";

function Priority
(
    {
        priority
    }
) {
    if (!priority || !priority.isVisible) {
        return null;
    }

    return (
        <Pill style={priority.style} bold={true}>
            {
                priority.showId ?
                    (
                        <>
                            {priority.id} -&nbsp;
                        </>
                    ) : null
            }

            {priority.description}
        </Pill>
    )
}

export default Priority;
