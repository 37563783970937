// Used from: https://stackoverflow.com/a/68751814
// Throttles the calling of `callbackFunction` every `limit` in ms.
// Ensures `callbackFunction` is not called too rapidly.
function throttle(callbackFunction, limit) {
    let wait = false;
    return function () {
        if (!wait) {
            callbackFunction.call();
            wait = true;
            setTimeout(function () {
                wait = false;
            }, limit);
        }
    }
}

export default throttle;
