import {HttpService} from "./http.service";
import {HttpMethod} from "../../enums/http-method.enum";
import UserSerializer from "../../data/serializers/user/user.serializer";
import UserApprovedSerializer from "../../data/serializers/user/user-approved.serializer";
import UserReducedSerializer from "../../data/serializers/user/user-reduced.serializer";

export class UserService extends HttpService {

    constructor(authContext) {
        super(authContext, "Users", new UserSerializer());
    }

    async login(username, password) {
        const data = {
            username: username,
            password: password,
        };
        const response = await this.request("Login", HttpMethod.POST, data, false);

        if (response && response.data.token) {
            return response.data.token;
        }
        return null;
    }

    async register(username, password, confirmPassword) {
        const data = {
            username: username,
            password: password,
            confirmPassword: confirmPassword,
        };
        const response = await this.request("Register", HttpMethod.POST, data, false);
        return !!response;
    }

    async profile(id) {
        const response = await this.request("Profile/" + id, HttpMethod.GET);

        if (response) {
            try {
                return this.serializer.fromJson(response.data);
            } catch (e) {
                // Do nothing
            }
        }

        return null;
    }

    async usersReduced() {
        const response = await this.request("Reduced", HttpMethod.GET);

        if (response) {
            try {
                return new UserReducedSerializer().fromJsonArray(response.data);
            } catch (e) {
                // Do nothing
            }
        }

        return null;
    }

    async users() {
        const response = await this.request("", HttpMethod.GET);

        if (response) {
            try {
                return new UserApprovedSerializer().fromJsonArray(response.data);
            } catch (e) {
                // Do nothing
            }
        }

        return null;
    }

    async toggleApproval(id) {
        const response = await this.request("ToggleApproval/" + id, HttpMethod.PUT);
        return !!response;
    }
}
