import {InvalidQuickFilterTypeException} from "../exceptions/invalid-quick-filter-type.exception";

export const QuickFilterType = {
    ALL: "ALL",
    NOTASSIGNED: "NOTASSIGNED",
    ASSIGNED: "ASSIGNED",
    ASSIGNEDTO: "ASSIGNEDTO",
    ASSIGNEDTOME: "ASSIGNEDTOME",
    REPORTEDBY: "REPORTEDBY",
    REPORTEDBYME: "REPORTEDBYME",
    RESOLVEDBY: "RESOLVEDBY",
    RESOLVEDBYME: "RESOLVEDBYME",
    CLOSED: "CLOSED",
}

export function QuickFilterTypeLabel(quickFilterType) {
    switch (quickFilterType) {
        case QuickFilterType.ALL:
            return "Alle tickets";
        case QuickFilterType.NOTASSIGNED:
            return "Niet toegewezen";
        case QuickFilterType.ASSIGNED:
        case QuickFilterType.ASSIGNEDTO:
            return "Toegewezen";
        case QuickFilterType.ASSIGNEDTOME:
            return "Toegewezen aan mij";
        case QuickFilterType.REPORTEDBY:
            return "Aangemaakt";
        case QuickFilterType.REPORTEDBYME:
            return "Aangemaakt door mij";
        case QuickFilterType.CLOSED:
        case QuickFilterType.RESOLVEDBY:
            return "Gesloten";
        case QuickFilterType.RESOLVEDBYME:
            return "Gesloten door mij";
        default:
            throw new InvalidQuickFilterTypeException(quickFilterType);
    }
}
