import {ExceptionType} from "../enums/exception-type.enum";
import {Exception} from "./exception";

export class NotFoundException extends Exception {
    errors;

    constructor() {
        super(`Not Found!`, ExceptionType.NOT_FOUND);
        this.specify("Pagina");
    }

    specify(item) {
        if (!item || item.length === 0) {
            return;
        }

        const itemCapitalized = item.charAt(0).toUpperCase() + item.toLowerCase().slice(1);
        this.errors = [`${itemCapitalized} kan niet worden gevonden.`];
    }
}
