import React from 'react';
import AuthenticatedLayout from "../../../components/Layout/AuthenticatedLayout/AuthenticatedLayout";
import ProblemIssueForm from "../../../components/Form/ProblemIssue/ProblemIssueForm";
import {CrudOperation} from "../../../shared/enums/crud-operation.enum";

function ProblemIssueCreate() {
    return (
        <AuthenticatedLayout backTo="/" title="Nieuwe problem issue aanmaken">
            <ProblemIssueForm crudOperation={CrudOperation.CREATE}/>
        </AuthenticatedLayout>
    );
}

export default ProblemIssueCreate
