import React, {useContext, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {ProblemIssueService} from "../../../shared/services/http-services/problem-issue.service";
import {useData} from "../../../shared/util/custom-hooks/use-data";
import {AuthContext} from "../../../shared/context/AuthContext";
import AuthenticatedLayout from "../../../components/Layout/AuthenticatedLayout/AuthenticatedLayout";
import Activities from "../../../components/Activities/Activities";
import Card from "../../../components/Card/Card";
import Button from "../../../components/Button/Button";
import {ButtonType} from "../../../shared/enums/button-type.enum";
import {IconType} from "../../../shared/enums/icon-type.enum";
import Loading from "../../Loading/Loading";
import ConfirmPopup from "../../../components/Popup/ConfirmPopup/ConfirmPopup";
import Style from "../../../shared/data/models/style.model";
import {Theme} from "../../../shared/enums/theme.enum";
import {Appearance} from "../../../shared/enums/appearance.enum";
import Status from "../../../components/ProblemIssue/Status/Status";
import {DataStatus} from "../../../shared/enums/data-status.enum";
import Info from "../../../components/InfoSection/Info/Info";
import User from "../../../components/User/User";
import InfoSection from "../../../components/InfoSection/InfoSection";
import Priority from "../../../components/ProblemIssue/Priority/Priority";
import "./Details.css";
import {useMediaQuery} from "../../../shared/util/custom-hooks/use-media-query";
import ProblemIssueDescription from "../../../components/ProblemIssue/Description/ProblemIssueDescription";
import PageException from "../../../components/Errors/PageException/PageException";
import ErrorsPopup from "../../../components/Errors/ErrorsPopup/ErrorsPopup";
import parseException from "../../../shared/util/parse-exception";
import {SuccessContext} from "../../../shared/context/SuccessContext";

function ProblemIssueDetails() {
    const smallScreen = useMediaQuery('(max-width: 991px)');
    const {showSuccess} = useContext(SuccessContext);
    const authContext = useContext(AuthContext);
    const navigate = useNavigate();
    const {id} = useParams();

    const issueService = new ProblemIssueService(authContext);
    const [issue, dataStatus, reload, , dataException] = useData(() => issueService.getIssue(id), {});

    const [confirmDelete, setConfirmDelete] = useState(false);
    const [deleteErrors, setDeleteErrors] = useState([]);

    if (dataStatus !== DataStatus.DONE && dataStatus !== DataStatus.ERROR) {
        return <Loading/>;
    }

    if (dataStatus === DataStatus.ERROR) {
        return (
            <AuthenticatedLayout title={"Details"} backTo="/">
                <PageException dataStatus={dataStatus}
                               query={async () => {
                                   await reload();
                               }}
                               itemName={"Problem issue details"}
                               exception={dataException}/>
            </AuthenticatedLayout>
        );
    }

    async function onDelete() {
        try {
            const response = await issueService.deleteIssue(id);

            if (response) {
                navigate("/", {state: {resetSelectedIssue: true}});
            }

            showSuccess("Problem issue is verwijderd!");
        } catch (e) {
            parseException(e, setDeleteErrors);
        }
    }

    return (
        <AuthenticatedLayout title={"Details"} backTo="/"
                             contentHeader={
                                 smallScreen ? (
                                     <>
                                         {
                                             issue.descriptionTypes.length > 0 ? (
                                                 <h2>Beschrijving</h2>
                                             ) : null
                                         }
                                     </>
                                 ) : (
                                     <h2>{issue.title}</h2>
                                 )
                             }
                             asideHeader={
                                 smallScreen ? (
                                     <h2>{issue.title}</h2>
                                 ) : null
                             }
                             aside={
                                 <Card className="details-information">
                                     <div>
                                         <Info label="Interne ID">{issue.id}</Info>
                                         <Info label="Externe ID">{issue.externalId}</Info>

                                         {
                                             issue.status && issue.status.isVisible ?
                                                 (
                                                     <Info label="Status">
                                                         <Status status={issue.status}/>
                                                     </Info>
                                                 ) : null
                                         }
                                         {
                                             issue.priority && issue.priority.isVisible ?
                                                 (
                                                     <Info label="Prioriteit">
                                                         <Priority priority={issue.priority}/>
                                                     </Info>
                                                 ) : null
                                         }

                                         <Info label="Bedrijf">{issue.company.description}</Info>
                                         <Info label="Winkel">{issue.store.description}</Info>
                                         <Info label="Onderdeel">{issue.equipment.description}</Info>
                                         <Info label="Applicatie">{issue.application.description}</Info>
                                     </div>

                                     <div className="details-information-dates">
                                         <div>
                                             {
                                                 issue.allocatedTo && issue.allocatedTo.id ? (
                                                     <Info label="Toegewezen aan"><User
                                                         user={issue.allocatedTo}/></Info>
                                                 ) : null
                                             }

                                             {
                                                 issue.openedOn && issue.openedOn.hasDate() && issue.openedBy && issue.openedBy.id ? (
                                                     <InfoSection label="Aangemaakt">
                                                         <Info label="op">{issue.openedOn.readableDate()}</Info>
                                                         <Info label="door"><User user={issue.openedBy}/> </Info>
                                                     </InfoSection>
                                                 ) : null
                                             }

                                             {
                                                 issue.mutatedOn && issue.mutatedOn.hasDate() && issue.mutatedBy && issue.mutatedBy.id ? (
                                                     <InfoSection label="Laatst gewijzigd">
                                                         <Info label="op">{issue.mutatedOn.readableDate()}</Info>
                                                         <Info label="door"><User user={issue.mutatedBy}/> </Info>
                                                     </InfoSection>
                                                 ) : null
                                             }
                                         </div>

                                         <div>
                                             {
                                                 issue.resolvedOn && issue.resolvedOn.hasDate() && issue.resolvedBy && issue.resolvedBy.id ? (
                                                     <>
                                                         <InfoSection label="Gesloten">
                                                             <Info label="op">{issue.resolvedOn.readableDate()}</Info>
                                                             <Info label="door"><User user={issue.resolvedBy}/> </Info>
                                                         </InfoSection>
                                                         <Info label="Oplostijd">{issue.resolveTime}</Info>
                                                         <Info label="Gefactureerd">{issue.billed ? "Ja" : "Nee"}</Info>
                                                     </>
                                                 ) : null
                                             }
                                         </div>
                                     </div>
                                 </Card>
                             }
                             pageButtonsVisible={authContext.isDeveloper}
                             pageButtons={
                                 <>
                                     <Button btnType={ButtonType.LINK}
                                             style={new Style(Theme.PRIMARY, Appearance.OUTLINE)}
                                             icon={IconType.PENCIL}
                                             args={{to: "/issue/edit/" + id}}>
                                         Wijzigen
                                     </Button>
                                     <Button style={new Style(Theme.DANGER, Appearance.OUTLINE)} icon={IconType.TRASH}
                                             args={{
                                                 onClick: () => {
                                                     setConfirmDelete(true);
                                                 }
                                             }}>
                                         Verwijderen
                                     </Button>
                                 </>
                             }>

            <ProblemIssueDescription issue={issue}/>
            <Activities
                label={<h3>Activiteit</h3>}
                activities={issue.activities}
                issueId={issue.id}
                reload={reload}/>

            <ConfirmPopup message="Weet je zeker dat je dit problem issue wilt verwijderen?"
                          visible={[confirmDelete, setConfirmDelete]}
                          onConfirm={onDelete}/>

            <ErrorsPopup header="Kan problem issue niet verwijderen."
                         errors={[deleteErrors, setDeleteErrors]}/>
        </AuthenticatedLayout>
    );
}

export default ProblemIssueDetails
