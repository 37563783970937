import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter as Router} from 'react-router-dom';
import './index.css';
import App from './App';
import AuthContextProvider from "./shared/context/AuthContext";
import ValuesContextProvider from "./shared/context/ValuesContext";
import SuccessContextProvider from "./shared/context/SuccessContext";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <Router>
            <SuccessContextProvider>
                <AuthContextProvider>
                    <ValuesContextProvider>
                        <App/>
                    </ValuesContextProvider>
                </AuthContextProvider>
            </SuccessContextProvider>
        </Router>
    </React.StrictMode>
);
