import axios from "axios";
import {HttpMethod} from "../../enums/http-method.enum";
import {InvalidHttpMethodException} from "../../exceptions/invalid-http-method.exception";
import {NotInitializedException} from "../../exceptions/not-initialized.exception";
import {BadRequestException} from "../../exceptions/bad-request.exception";
import {NotFoundException} from "../../exceptions/not-found.exception";

export class HttpService {
    #apiBase = process.env.REACT_APP_API_BASE;
    #maxTries = 3;

    constructor(authContext, servicePath, serializer) {
        this.authContext = authContext;
        this.servicePath = servicePath;
        this.serializer = serializer;
    }

    async request(path, httpMethod, data = {}, authenticatedRequest = true, extraHeaders = {}) {
        let url = this.#getUrl(path);
        let config = this.#getConfig(authenticatedRequest, extraHeaders);

        if (url && httpMethod && httpMethod !== HttpMethod.UNKNOWN) {
            return await this.#handleAndExecute(url, httpMethod, data, config);
        }

        return null;
    }

    #getUrl(path) {
        if (path) {
            return `${this.#apiBase}/${this.servicePath}/${path}`
        }
        return `${this.#apiBase}/${this.servicePath}`
    }

    #getToken() {
        return localStorage.getItem('token');
    }

    #getConfig(authenticatedRequest, extraHeaders) {
        let config = {
            headers: {
                ...extraHeaders,
                "Content-Type": "application/json"
            }
        };

        if (authenticatedRequest) {
            let token = this.#getToken();
            config.headers = {
                ...config.headers,
                "Authorization": `Bearer ${token}`
            }
        }

        return config;
    }

    async #handleAndExecute(url, httpMethod, data, config, currentTry = 1) {
        try {
            return await this.#execute(url, httpMethod, data, config);
        } catch (e) {
            if (currentTry >= this.#maxTries) {
                // If retries did not succeed throw error up
                throw e;
            }

            // Handle error and retry request
            if (this.#handle(e)) {
                return await this.#handleAndExecute(url, httpMethod, data, config, currentTry + 1);
            }

            return null;
        }
    }

    async #execute(url, httpMethod, data, config) {
        switch (httpMethod) {
            case HttpMethod.GET:
                return await this.#get(url, data, config);
            case HttpMethod.POST:
                return await this.#post(url, data, config);
            case HttpMethod.PUT:
                return await this.#put(url, data, config);
            case HttpMethod.DELETE:
                return await this.#delete(url, data, config);
            default:
                throw new InvalidHttpMethodException(httpMethod);
        }
    }

    #toUrlParams(url, data) {
        if (Object.keys(data).length > 0) {
            let queryString = new URLSearchParams(data).toString()

            if (queryString) {
                let separator = "?";
                if (url.includes("?")) {
                    separator = "&";
                }

                url = `${url}${separator}${queryString}`;
            }
        }

        return url;
    }

    async #get(url, data, config) {
        url = this.#toUrlParams(url, data);
        return await axios.get(url, config);
    }

    async #post(url, data, config) {
        return await axios.post(url, data, config);
    }

    async #put(url, data, config) {
        return await axios.put(url, data, config);
    }

    async #delete(url, data, config) {
        config = {
            ...config,
            data: data
        }

        return await axios.delete(url, config);
    }

    #handle(error) {
        if (error.name === "AxiosError") {
            switch (error.response.status) {
                case 400:
                    let errors = error.response.data;
                    if (typeof errors === "string") {
                        errors = [errors];
                    } else if (errors.errors) {
                        let newErrors = [];

                        Object.keys(errors.errors).forEach(key => {
                            newErrors.push(...errors.errors[key].map(error => `${key}: ${error}`));
                        });

                        errors = newErrors;
                    }

                    throw new BadRequestException(errors);
                case 401:
                    if (this.authContext) {
                        this.authContext.clearAuthentication(true);
                        return false;
                    }
                    throw new NotInitializedException("http-service", "AuthContext was not set.");
                case 404:
                    throw new NotFoundException();
                default:
                    break;
            }
        }

        return true;
    }
}
