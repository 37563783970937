// Copied from: https://github.com/codevor/js-semaphore/blob/master/src/semaphore.js

import {timespan} from "./timespan";

const Semaphore = ({resources = 1, start = resources} = {}) => {
    let counter = start >= resources ? resources : start;

    const acquire = () => {
        if (counter > 0) {
            counter -= 1;
            return Promise.resolve();
        }

        return new Promise(resolve => {
            const acquireInterval = setInterval(() => {
                if (counter > 0) {
                    counter -= 1;
                    clearInterval(acquireInterval);

                    resolve();
                }
            }, timespan() * 1000);
        });
    };

    const release = () => {
        if (counter < resources) {
            counter += 1;
        }
    };

    return {
        acquire,
        release
    };
};

export default Semaphore;
