export default class ProblemIssueParams {
    id;
    externalId;
    title;
    status;

    companyId;
    storeId;
    equipmentId;
    applicationId;
    priorityId;

    description;
    solution;
    siteCommunication;

    allocatedToId;
    billed;

    constructor(id, externalId, title, status, resolveTime,
                companyId, storeId, equipmentId, applicationId, priorityId,
                description, solution, siteCommunication,
                allocatedToId, billed) {
        if (id) {
            this.id = id;
        }

        if (externalId) {
            this.externalId = externalId;
        }

        if (title) {
            this.title = title;
        }

        if (status) {
            this.status = status;
        }

        if (resolveTime) {
            this.resolveTime = resolveTime;
        }

        if (companyId || companyId === 0) {
            this.companyId = companyId;
        }

        if (storeId || storeId === 0) {
            this.storeId = storeId;
        }

        if (equipmentId || equipmentId === 0) {
            this.equipmentId = equipmentId;
        }

        if (applicationId || applicationId === 0) {
            this.applicationId = applicationId;
        }

        if (priorityId || priorityId === 0) {
            this.priorityId = priorityId;
        }

        if (description) {
            this.description = description;
        }

        if (solution) {
            this.solution = solution;
        }

        if (siteCommunication) {
            this.siteCommunication = siteCommunication;
        }

        if (allocatedToId) {
            this.allocatedToId = allocatedToId;
        }

        if (billed) {
            this.billed = billed === "true";
        }
    }
}
