import React, {useContext, useState} from 'react';
import {Link} from "react-router-dom";
import "./Navbar.css";
import MenuItem from "./Menu/MenuItem/MenuItem";
import Menu from "./Menu/Menu";
import {AuthContext} from "../../../../shared/context/AuthContext";
import SubMenu from "./Menu/SubMenu/SubMenu";

function Navbar() {
    const {user, isAdmin} = useContext(AuthContext);
    let [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    return (
        <nav id="site-nav" className={"allow-selection " + (mobileMenuOpen ? "mobile-menu-open" : "")}>
            <button className={"mobile-menu-toggle reset-btn " + (mobileMenuOpen ? "mobile-menu-open" : "")}
                    type="button"
                    onClick={() => {
                        setMobileMenuOpen(!mobileMenuOpen);
                    }}
                    aria-controls="main-menu"
                    aria-expanded="false">
                <span></span>
                <span></span>
                <span></span>
            </button>

            <div className="nav-wrapper">
                <Menu menuItems={
                    <>
                        <MenuItem to="/" state={{reset: true}} name="Overzicht"/>
                        {
                            isAdmin ? (
                                <SubMenu name="Admin" subMenuItems={
                                    <>
                                        <MenuItem to="/users" name="Gebruikers"/>
                                    </>
                                }/>
                            ) : null
                        }
                    </>
                }/>
                <div className="site-logo">
                    <Link to="/"
                          state={{reset: true}}>
                        <img width="125" src="/images/AXI_logo.png" alt="Logo"/>
                    </Link>
                </div>
                <Menu menuItems={
                    <>
                        <SubMenu name={user.fullName} subMenuItems={
                            <>
                                <MenuItem to="/account" name="Account"/>
                                <MenuItem to={"/profile/" + user.id} name="Profiel"/>
                                <MenuItem to="/logout" name="Uitloggen"/>
                            </>
                        }/>
                    </>
                }/>
            </div>
        </nav>
    )
}

export default Navbar
