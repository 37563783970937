import {ExceptionType} from "../enums/exception-type.enum";

function parseException(exception, setErrors, specifiedItem = "", defaultMessage = "Er is een onbekende fout opgetreden.") {
    if (!exception || !setErrors) {
        return;
    }

    if (exception.name === ExceptionType.BAD_REQUEST) {
        setErrors(exception.errors);
    } else if (exception.name === ExceptionType.NOT_FOUND) {
        exception.specify(specifiedItem);
        setErrors(exception.errors);
    } else {
        setErrors([defaultMessage]);
    }
}

export default parseException;
