import {useRef, useState} from "react";
import MenuItem from "../MenuItem/MenuItem";
import {useOutsideAlerter} from "../../../../../../shared/util/custom-hooks/use-outside-alerter";
import {getIcon, IconType} from "../../../../../../shared/enums/icon-type.enum";

function SubMenu
(
    {
        name,
        subMenuItems
    }
) {
    const subMenuRef = useRef(null);
    useOutsideAlerter(subMenuRef, () => {
        setSubMenuOpen(false)
    });

    let [subMenuOpen, setSubMenuOpen] = useState(false);

    function toggleSubMenu(e) {
        setSubMenuOpen(!subMenuOpen);
        e.preventDefault();
    }

    return (
        <li ref={subMenuRef} className="has-sub-menu">
            <MenuItem onClick={toggleSubMenu} name={
                <>
                    {name}<span className="sub-menu-indicator">{getIcon(IconType.CHEVRON_DOWN)}</span>
                </>
            }/>
            <div className={"sub-menu-wrapper " + (subMenuOpen ? "sub-menu-wrapper-visible" : "")}>
                <ul className="sub-menu">
                    {subMenuItems}
                </ul>
            </div>
        </li>
    )
}

export default SubMenu;
