import QuickFilterItem from "./QuickFilter/QuickFilterItem";
import {QuickFilterType, QuickFilterTypeLabel} from "../../../shared/enums/quick-filter-type.enum";
import React, {useEffect, useState} from "react";
import QuickFilter from "../../../shared/data/models/quick-filter.model";
import Filter from "../../Filter/Filter";

function QuickFilters
(
    {
        quickFilter: [quickFilter, setQuickFilter],
        disabled = false,
        quickFilters = Object.keys(QuickFilterType)
    }
) {
    const [selectedType, setSelectedType] = useState(quickFilter.type);

    // Update quickFilterParams when selectedType changes
    useEffect(() => {
        setQuickFilter(new QuickFilter(selectedType, quickFilter.userId));
    }, [selectedType]);

    // Check if there are any quickFilters to show and if the selected quickFilter is in the list
    if (!quickFilters || quickFilters.length === 0 || !quickFilters.includes(quickFilter.type)) {
        return null;
    }

    return (
        <Filter selectedFilter={[selectedType, setSelectedType]}
                filters={quickFilters.map((key) => {
                    return {
                        value: key, label: QuickFilterTypeLabel(key)
                    }
                })}
                className="quick-filters"
                smallScreenSize={991}
                label="Snel filteren"
                disabled={disabled}>
            {
                quickFilters.map((quickFilterType) => {
                    return (
                        <QuickFilterItem key={quickFilterType} type={quickFilterType}
                                         selected={selectedType === quickFilterType}
                                         setSelectedType={setSelectedType}
                                         disabled={disabled}/>
                    );
                })
            }
        </Filter>
    );
}

export default QuickFilters;
