import {InvalidOrderByException} from "../exceptions/invalid-order-by.exception";

export const ProblemIssueOrderBy = {
    UpdatedOn: "UpdatedOn",
    OpenedOn: "OpenedOn",
    Title: "Title",
    Id: "Id"
}

export function ProblemIssueOrderByLabel(orderBy) {
    switch (orderBy) {
        case ProblemIssueOrderBy.UpdatedOn:
            return "Laatst bijgewerkt";
        case ProblemIssueOrderBy.OpenedOn:
            return "Geopend op";
        case ProblemIssueOrderBy.Title:
            return "Titel";
        case ProblemIssueOrderBy.Id:
            return "Interne ID";
        default:
            throw new InvalidOrderByException(orderBy);
    }
}
