import React from 'react';
import Pill from "../../Pill/Pill";

function Status
(
    {
        status
    }
) {
    if (!status || !status.isVisible) {
        return null;
    }

    return (
        <Pill style={status.style} bold={true}>
            {status.description}
        </Pill>
    )
}

export default Status;
