import IdentifierSerializer from "./identifier.serializer";
import Descriptor from "../models/descriptor.model";

export default class DescriptorSerializer extends IdentifierSerializer {
    fromJson(json) {
        const descriptor = new Descriptor(super.fromJson(json));
        descriptor.description = json.description;

        return descriptor;
    }

    fromJsonArray(jsonArray) {
        try {
            if (jsonArray && jsonArray.length > 0) {
                return jsonArray.map(json => this.fromJson(json));
            }
        } catch (e) {
            // Do nothing
        }

        return [];
    }

    toJson(descriptor) {
        let json = super.toJson(descriptor);
        json.description = descriptor.description;

        return json;
    }

    toJsonArray(descriptors) {
        try {
            if (descriptors && descriptors.length > 0) {
                return descriptors.map(descriptor => this.toJson(descriptor));
            }
        } catch (e) {
            // Do nothing
        }

        return [];
    }
}
