import {jwtDecode} from "jwt-decode";

// Checks if the token is valid and not expired
function checkIfTokenIsValid(token) {
    if(!token) {
        return false;
    }

    const decodedToken = jwtDecode(token);
    return Date.now() < decodedToken.exp * 1000;
}

export default checkIfTokenIsValid;
