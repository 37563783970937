import Popup from "../Popup";
import Button from "../../Button/Button";
import {useState} from "react";
import Style from "../../../shared/data/models/style.model";
import {Theme} from "../../../shared/enums/theme.enum";
import {Appearance} from "../../../shared/enums/appearance.enum";

function ConfirmPopup
(
    {
        title = "Let op!",
        message,
        visible: [visible = false, setVisible],
        onConfirm
    }
) {
    const [processing, setProcessing] = useState(false);

    async function handleConfirm() {
        setProcessing(true);
        await onConfirm();
        closePopup();
    }

    function closePopup() {
        setVisible(false);
        setProcessing(false);
    }

    if (!visible) {
        return null;
    }

    return (
        <Popup title={title} closePopup={closePopup} processing={processing}>
            <>
                <p>{message}</p>
                <div className="btn-container">
                    <Button style={new Style(Theme.PRIMARY, Appearance.OUTLINE)}
                            args={{onClick: closePopup}}>Nee</Button>
                    <Button style={new Style(Theme.DANGER)} args={{onClick: handleConfirm}}>Ja</Button>
                </div>
            </>
        </Popup>
    );
}

export default ConfirmPopup;
