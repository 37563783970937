import {Appearance} from "../../enums/appearance.enum";
import {Theme} from "../../enums/theme.enum";

export default class Style {
    theme;
    appearance;

    constructor(theme = Theme.DARK, appearance = Appearance.REGULAR) {
        this.theme = theme;
        this.appearance = appearance;
    }

    getClassName(prefix = "") {
        let className = prefix ? `${prefix} ` : "";
        prefix = prefix ? `${prefix}-` : "";

        let additive = "";
        switch (this.appearance) {
            default:
            case Appearance.REGULAR:
                additive = prefix;
                break;
            case Appearance.OUTLINE:
                className += `${prefix}outline `;
                additive = `${prefix}outline-`;
                break;
            case Appearance.MINIMAL:
                className += `${prefix}minimal `;
                additive = `${prefix}minimal-`;
                break;
        }

        switch (this.theme) {
            default:
            case Theme.DARK:
                break;
            case Theme.LIGHT:
                className += `${additive}light`;
                break;
            case Theme.PRIMARY:
                className += `${additive}primary`;
                break;
            case Theme.SECONDARY:
                className += `${additive}secondary`;
                break;
            case Theme.INFO:
                className += `${additive}info`;
                break;
            case Theme.SUCCESS:
                className += `${additive}success`;
                break;
            case Theme.WARNING:
                className += `${additive}warning`;
                break;
            case Theme.DANGER:
                className += `${additive}danger`;
                break;
        }

        return className;
    }
}
