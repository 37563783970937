import {Appearance} from "../../enums/appearance.enum";
import {Theme} from "../../enums/theme.enum";
import Style from "./style.model";
import {ProblemIssueStatus} from "../../enums/problem-issue-status.enum";

export default class Status {
    problemIssueStatus;
    isVisible;
    description;
    style;

    constructor(dtoStatus) {
        this.setStatus(this.#getStatus(dtoStatus));
    }

    #getStatus(dtoStatus) {
        switch (dtoStatus.toUpperCase()) {
            case "ALL":
                return ProblemIssueStatus.ALL;
            case "TODO":
                return ProblemIssueStatus.TODO;
            case "INPROGRESS":
                return ProblemIssueStatus.INPROGRESS;
            case "RESOLVED":
                return ProblemIssueStatus.RESOLVED;
            case "CLOSED":
                return ProblemIssueStatus.CLOSED;
            case "CANCELLED":
                return ProblemIssueStatus.CANCELLED;
            default:
                return ProblemIssueStatus.UNKNOWN;
        }
    }

    setStatus(status) {
        this.problemIssueStatus = status;
        this.isVisible = this.#isVisible();
        this.description = this.#getDescription();
        this.style = this.#style();
    }

    #isVisible() {
        return this.problemIssueStatus !== ProblemIssueStatus.UNKNOWN && this.problemIssueStatus !== ProblemIssueStatus.ALL;
    }

    #getDescription() {
        switch (this.problemIssueStatus) {
            case ProblemIssueStatus.ALL:
                return "";
            case ProblemIssueStatus.TODO:
                return "Te Doen";
            case ProblemIssueStatus.INPROGRESS:
                return "Bezig";
            case ProblemIssueStatus.RESOLVED:
                return "Afgerond";
            case ProblemIssueStatus.CLOSED:
                return "Gesloten";
            case ProblemIssueStatus.CANCELLED:
                return "Geannuleerd";
            case ProblemIssueStatus.UNKNOWN:
            default:
                return "Onbekend";
        }
    }

    #style() {
        let theme;
        let appearance = Appearance.REGULAR;

        switch (this.problemIssueStatus) {
            case ProblemIssueStatus.TODO:
                theme = Theme.INFO;
                break;
            case ProblemIssueStatus.INPROGRESS:
                theme = Theme.WARNING;
                break;
            case ProblemIssueStatus.CLOSED:
            case ProblemIssueStatus.RESOLVED:
                theme = Theme.SUCCESS;
                break;
            case ProblemIssueStatus.CANCELLED:
                theme = Theme.DANGER;
                break;
            case ProblemIssueStatus.UNKNOWN:
            case ProblemIssueStatus.ALL:
            default:
                theme = Theme.DARK;
                appearance = Appearance.OUTLINE;
                break;
        }

        return new Style(theme, appearance);
    }
}
