import React, {useEffect, useState} from 'react';
import ProblemIssueSearchParams
    from "../../../../shared/data/models/problem-issue/params/search/problem-issue-search-params.model";
import {ProblemIssueSearchType} from "../../../../shared/enums/problem-issue-search-type.enum";
import Input from "../../../Form/Input/Input";
import Form from "../../../Form/Form";
import ProblemIssueAdvancedSearchParams
    from "../../../../shared/data/models/problem-issue/params/search/problem-issue-advanced-search-params.model";
import {IconType} from "../../../../shared/enums/icon-type.enum";
import Button from "../../../Button/Button";
import Style from "../../../../shared/data/models/style.model";
import {Theme} from "../../../../shared/enums/theme.enum";
import {Appearance} from "../../../../shared/enums/appearance.enum";
import ProblemIssueApiValues from "../../ApiValues/ProblemIssueApiValues";
import {DescriptionType, DescriptionTypeLabel} from "../../../../shared/enums/description-type.enum";
import FormPanel from "../../../Form/Panel/FormPanel";
import {ProblemIssueStatus} from "../../../../shared/enums/problem-issue-status.enum";
import Status from "../../../../shared/data/models/status.model";
import {useMediaQuery} from "../../../../shared/util/custom-hooks/use-media-query";

function AdvancedSearch(
    {
        disabled = false,
        searchParams: [searchParams, setSearchParams],
        advancedSearchVisible: [advancedSearchVisible, setAdvancedSearchVisible],
        resetSearch
    }
) {
    const smallScreen = useMediaQuery('(max-width: 991px)');

    const [title, setTitle] = useState(searchParams.params.title || "");
    const [id, setId] = useState(searchParams.params.id || "");
    const [externalId, setExternalId] = useState(searchParams.params.externalId || "");
    const [status, setStatus] = useState(searchParams.params.status || ProblemIssueStatus.ALL);

    const [company, setCompany] = useState(searchParams.params.company || "");
    const [store, setStore] = useState(searchParams.params.store || "");
    const [equipment, setEquipment] = useState(searchParams.params.equipment || "");
    const [application, setApplication] = useState(searchParams.params.application || "");
    const [priority, setPriority] = useState(searchParams.params.priority || "");

    const [description, setDescription] = useState(searchParams.params.description || "");
    const [solution, setSolution] = useState(searchParams.params.solution || "");
    const [siteCommunication, setSiteCommunication] = useState(searchParams.params.siteCommunication || "");

    const [openedFrom, setOpenedFrom] = useState(searchParams.params.openedFrom || "");
    const [openedTo, setOpenedTo] = useState(searchParams.params.openedTo || "");
    const [openedBy, setOpenedBy] = useState(searchParams.params.openedBy || "");

    const [mutatedFrom, setMutatedFrom] = useState(searchParams.params.mutatedFrom || "");
    const [mutatedTo, setMutatedTo] = useState(searchParams.params.mutatedTo || "");
    const [mutatedBy, setMutatedBy] = useState(searchParams.params.mutatedBy || "");

    const [resolvedFrom, setResolvedFrom] = useState(searchParams.params.resolvedFrom || "");
    const [resolvedTo, setResolvedTo] = useState(searchParams.params.resolvedTo || "");
    const [resolvedBy, setResolvedBy] = useState(searchParams.params.resolvedBy || "");

    const [resolveTimeFrom, setResolveTimeFrom] = useState(searchParams.params.resolveTimeFrom || "");
    const [resolveTimeTo, setResolveTimeTo] = useState(searchParams.params.resolveTimeTo || "");

    const [allocatedTo, setAllocatedTo] = useState(searchParams.params.allocatedTo || "");
    const [billed, setBilled] = useState(searchParams.params.billed || "");

    useEffect(() => {
        resetFields();
    }, [searchParams]);

    function advancedSearch() {
        if (hasData()) {
            const advancedSearchParams = new ProblemIssueSearchParams(
                ProblemIssueSearchType.ADVANCED,
                createParams()
            );

            setSearchParams(advancedSearchParams);

            if (smallScreen) {
                setAdvancedSearchVisible(false);
            }
        } else {
            setSearchParams(new ProblemIssueSearchParams(ProblemIssueSearchType.NONE));
        }
    }

    function hasData() {
        return title || id || externalId || (status && status !== ProblemIssueStatus.ALL) ||
            (company || company === 0) || (store || store === 0) || (equipment || equipment === 0) || (application || application === 0) || (priority || priority === 0) ||
            description || solution || siteCommunication || openedFrom || openedTo || openedBy || mutatedFrom ||
            mutatedTo || mutatedBy || resolvedFrom || resolvedTo || resolvedBy || resolveTimeFrom || resolveTimeTo || allocatedTo || billed;
    }

    function createParams() {
        return new ProblemIssueAdvancedSearchParams(title, id, externalId, status, company, store, equipment,
            application, priority, description, solution, siteCommunication, openedFrom, openedTo, openedBy,
            mutatedFrom, mutatedTo, mutatedBy, resolvedFrom, resolvedTo, resolvedBy, resolveTimeFrom, resolveTimeTo, allocatedTo, billed);
    }

    function sanitizeId(id) {
        return id || id === 0 ? id : "";
    }

    function resetFields() {
        setTitle(searchParams.params.title || "");
        setId(searchParams.params.id || "");
        setExternalId(searchParams.params.externalId || "");
        setStatus(searchParams.params.status || ProblemIssueStatus.ALL);

        setCompany(sanitizeId(searchParams.params.company));
        setStore(sanitizeId(searchParams.params.store));
        setEquipment(sanitizeId(searchParams.params.equipment));
        setApplication(sanitizeId(searchParams.params.application));
        setPriority(sanitizeId(searchParams.params.priority));

        setDescription(searchParams.params.description || "");
        setSolution(searchParams.params.solution || "");
        setSiteCommunication(searchParams.params.siteCommunication || "");

        setOpenedFrom(searchParams.params.openedFrom || "");
        setOpenedTo(searchParams.params.openedTo || "");
        setOpenedBy(searchParams.params.openedBy || "");

        setMutatedFrom(searchParams.params.mutatedFrom || "");
        setMutatedTo(searchParams.params.mutatedTo || "");
        setMutatedBy(searchParams.params.mutatedBy || "");

        setResolvedFrom(searchParams.params.resolvedFrom || "");
        setResolvedTo(searchParams.params.resolvedTo || "");
        setResolvedBy(searchParams.params.resolvedBy || "");

        setResolveTimeFrom(searchParams.params.resolveTimeFrom || "");
        setResolveTimeTo(searchParams.params.resolveTimeTo || "");

        setAllocatedTo(searchParams.params.allocatedTo || "");
        setBilled(searchParams.params.billed || "");
    }

    return (
        <>
            <header>
                <h2>Geavanceerd zoeken</h2>
            </header>
            <main>
                <Form
                    handleSubmit={advancedSearch}
                    disabled={disabled}
                    buttonText="Geavanceerd zoeken"
                    icon={IconType.MAGNIFYING_GLASS}
                    inputs={
                        [
                            <Input key="advanced-search-title"
                                   nameAttribute="advanced-search-title"
                                   labelText="Titel"
                                   state={[title, setTitle]}/>,
                            <Input key="advanced-search-id"
                                   nameAttribute="advanced-search-id"
                                   labelText="Interne id"
                                   state={[id, setId]}/>,
                            <Input key="advanced-search-external-id"
                                   nameAttribute="advanced-search-external-id"
                                   labelText="Externe id"
                                   state={[externalId, setExternalId]}/>,
                            <Input key="advanced-search-status"
                                   nameAttribute="advanced-search-status"
                                   inputType="select"
                                   labelText="Status"
                                   state={[status, setStatus]}
                                   options={
                                       Object.keys(ProblemIssueStatus)
                                           .filter(aStatus => aStatus !== ProblemIssueStatus.UNKNOWN)
                                           .map(key => {
                                               const status = new Status(key);
                                               return {
                                                   value: status.problemIssueStatus,
                                                   label: status.description
                                               }
                                           })
                                   }/>,

                            <FormPanel key="advanced-search-api-values"
                                       initialCollapsed={!company && !store && !equipment && !application && !priority}
                                       title="Product"
                                       inputs={
                                           [
                                               <ProblemIssueApiValues key="advanced-search-values"
                                                                      company={[company, setCompany]}
                                                                      store={[store, setStore]}
                                                                      equipment={[equipment, setEquipment]}
                                                                      application={[application, setApplication]}
                                                                      priority={[priority, setPriority]}/>
                                           ]
                                       }/>,

                            <FormPanel key="advanced-search-descriptions"
                                       initialCollapsed={!description && !solution && !siteCommunication}
                                       title="Beschrijvingen"
                                       inputs={
                                           [
                                               <Input key="advanced-search-description"
                                                      nameAttribute="advanced-search-description"
                                                      inputType="textarea"
                                                      labelText={DescriptionTypeLabel(DescriptionType.REGULAR)}
                                                      state={[description, setDescription]}/>,
                                               <Input key="advanced-search-solution"
                                                      nameAttribute="advanced-search-solution"
                                                      inputType="textarea"
                                                      labelText={DescriptionTypeLabel(DescriptionType.SOLUTION)}
                                                      state={[solution, setSolution]}/>,
                                               <Input key="advanced-search-site-communication"
                                                      nameAttribute="advanced-search-site-communication"
                                                      inputType="textarea"
                                                      labelText={DescriptionTypeLabel(DescriptionType.SITE_COMMUNICATION)}
                                                      state={[siteCommunication, setSiteCommunication]}/>,
                                           ]
                                       }/>,

                            <FormPanel key="advanced-search-opened"
                                       initialCollapsed={!openedFrom && !openedTo && !openedBy}
                                       title="Aangemaakt"
                                       inputs={
                                           [
                                               <Input key="advanced-search-opened-from"
                                                      nameAttribute="advanced-search-opened-from"
                                                      inputType="datetime-local"
                                                      labelText="Van"
                                                      state={[openedFrom, setOpenedFrom]}/>,
                                               <Input key="advanced-search-opened-to"
                                                      nameAttribute="advanced-search-opened-to"
                                                      inputType="datetime-local"
                                                      labelText="Tot"
                                                      state={[openedTo, setOpenedTo]}/>,
                                               <Input key="advanced-search-opened-by"
                                                      nameAttribute="advanced-search-opened-by"
                                                      inputType="user"
                                                      labelText="Door"
                                                      state={[openedBy, setOpenedBy]}/>,

                                           ]
                                       }/>,

                            <FormPanel key="advanced-search-mutated"
                                       initialCollapsed={!mutatedFrom && !mutatedTo && !mutatedBy}
                                       title="Laatst gewijzigd"
                                       inputs={
                                           [
                                               <Input key="advanced-search-mutated-from"
                                                      nameAttribute="advanced-search-mutated-from"
                                                      inputType="datetime-local"
                                                      labelText="Van"
                                                      state={[mutatedFrom, setMutatedFrom]}/>,
                                               <Input key="advanced-search-mutated-to"
                                                      nameAttribute="advanced-search-mutated-to"
                                                      inputType="datetime-local"
                                                      labelText="Tot"
                                                      state={[mutatedTo, setMutatedTo]}/>,
                                               <Input key="advanced-search-mutated-by"
                                                      nameAttribute="advanced-search-mutated-by"
                                                      inputType="user"
                                                      labelText="Door"
                                                      state={[mutatedBy, setMutatedBy]}/>,
                                           ]
                                       }/>,

                            <FormPanel key="advanced-search-resolved"
                                       initialCollapsed={!resolvedFrom && !resolvedTo && !resolvedBy}
                                       title="Opgelost"
                                       inputs={
                                           [
                                               <Input key="advanced-search-resolved-from"
                                                      nameAttribute="advanced-search-resolved-from"
                                                      inputType="datetime-local"
                                                      labelText="Van"
                                                      state={[resolvedFrom, setResolvedFrom]}/>,
                                               <Input key="advanced-search-resolved-to"
                                                      nameAttribute="advanced-search-resolved-to"
                                                      inputType="datetime-local"
                                                      labelText="Tot"
                                                      state={[resolvedTo, setResolvedTo]}/>,
                                               <Input key="advanced-search-resolved-by"
                                                      nameAttribute="advanced-search-resolved-by"
                                                      inputType="user"
                                                      labelText="Door"
                                                      state={[resolvedBy, setResolvedBy]}/>
                                           ]
                                       }/>,

                            <FormPanel key="advanced-search-resolve-time"
                                       initialCollapsed={!resolveTimeFrom && !resolveTimeTo}
                                       title="Oplostijd"
                                       inputs={
                                           [
                                               <Input key="advanced-search-resolve-time-from"
                                                      nameAttribute="advanced-search-resolve-time-from"
                                                      inputType="number"
                                                      labelText="Van"
                                                      state={[resolveTimeFrom, setResolveTimeFrom]}/>,
                                               <Input key="advanced-search-resolve-time-to"
                                                      nameAttribute="advanced-search-resolve-time-to"
                                                      inputType="number"
                                                      labelText="Tot"
                                                      state={[resolveTimeTo, setResolveTimeTo]}/>
                                           ]
                                       }/>,

                            <FormPanel key="advanced-search-other"
                                       initialCollapsed={!allocatedTo && !billed}
                                       title="Overig"
                                       inputs={
                                           [
                                               <Input key="advanced-search-allocated-to"
                                                      nameAttribute="advanced-search-allocated-to"
                                                      inputType="user"
                                                      labelText="Toegewezen aan"
                                                      state={[allocatedTo, setAllocatedTo]}/>,
                                               <Input key="advanced-search-billed"
                                                      nameAttribute="advanced-search-billed"
                                                      inputType="select"
                                                      labelText="Gefactureerd"
                                                      state={[billed, setBilled]}
                                                      options={
                                                          [
                                                              {
                                                                  value: "", label: ""
                                                              },
                                                              {
                                                                  value: "true", label: "Ja"
                                                              },
                                                              {
                                                                  value: "false", label: "Nee"
                                                              },
                                                          ]
                                                      }/>
                                           ]
                                       }/>,
                        ]
                    }
                    warning={description || solution || siteCommunication ? (
                        <>
                            <strong>Let op:</strong> zoekopdrachten op
                            <span>
                                &nbsp;"
                                {
                                    [
                                        description && DescriptionTypeLabel(DescriptionType.REGULAR).toLowerCase(),
                                        solution && DescriptionTypeLabel(DescriptionType.SOLUTION).toLowerCase(),
                                        siteCommunication && DescriptionTypeLabel(DescriptionType.SITE_COMMUNICATION).toLowerCase()
                                    ].filter(value => value).join(", ")
                                }
                                "&nbsp;
                            </span>
                            kunnen erg lang duren <i>(1-2 min)</i>!
                        </>
                    ) : null}
                    extraButtons={
                        <Button title="Reset geavanceerd zoeken" icon={IconType.FILTER_CIRCLE_XMARK}
                                style={new Style(Theme.DARK, Appearance.OUTLINE)}
                                args={{
                                    onClick: resetSearch,
                                    disabled: disabled
                                }}/>
                    }/>
            </main>
        </>
    )
}

export default AdvancedSearch
