export const ExceptionType = {
    DEFAULT: "DEFAULT_EXCEPTION",
    INVALID_HTTP_METHOD: "INVALID_HTTP_METHOD_EXCEPTION",
    INVALID_ORDER_BY: "INVALID_ORDER_BY_EXCEPTION",
    INVALID_QUICK_FILTER_TYPE: "INVALID_QUICK_FILTER_TYPE_EXCEPTION",
    INVALID_ACTIVITY_TYPE: "INVALID_ACTIVITY_TYPE_EXCEPTION",
    INVALID_DESCRIPTION_TYPE: "INVALID_DESCRIPTION_TYPE_EXCEPTION",
    NOT_INITIALIZED: "NOT_INITIALIZED_EXCEPTION",
    BAD_REQUEST: "BAD_REQUEST_EXCEPTION",
    NOT_FOUND: "NOT_FOUND_EXCEPTION",
}
