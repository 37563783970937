import {ProblemIssueStatus} from "../../../../../enums/problem-issue-status.enum";

export default class ProblemIssueAdvancedSearchParams {
    constructor(title, id, externalId, status, company, store, equipment,
                application, priority, description, solution, siteCommunication, openedFrom, openedTo, openedBy,
                mutatedFrom, mutatedTo, mutatedBy, resolvedFrom, resolvedTo, resolvedBy, resolveTimeFrom, resolveTimeTo, allocatedTo, billed) {
        if (title) {
            this.title = title;
        }

        if (id) {
            this.id = id;
        }

        if (externalId) {
            this.externalId = externalId;
        }

        if (status && status !== ProblemIssueStatus.ALL) {
            this.status = status;
        }

        if (company || company === 0) {
            this.company = company;
        }

        if (store || store === 0) {
            this.store = store;
        }

        if (equipment || equipment === 0) {
            this.equipment = equipment;
        }

        if (application || application === 0) {
            this.application = application;
        }

        if (priority || priority === 0) {
            this.priority = priority;
        }

        if (description) {
            this.description = description;
        }

        if (solution) {
            this.solution = solution;
        }

        if (siteCommunication) {
            this.siteCommunication = siteCommunication;
        }

        if (openedFrom) {
            this.openedFrom = openedFrom;
        }

        if (openedTo) {
            this.openedTo = openedTo;
        }

        if (openedBy) {
            this.openedBy = openedBy;
        }

        if (mutatedFrom) {
            this.mutatedFrom = mutatedFrom;
        }

        if (mutatedTo) {
            this.mutatedTo = mutatedTo;
        }

        if (mutatedBy) {
            this.mutatedBy = mutatedBy;
        }

        if (resolvedFrom) {
            this.resolvedFrom = resolvedFrom;
        }

        if (resolvedTo) {
            this.resolvedTo = resolvedTo;
        }

        if (resolvedBy) {
            this.resolvedBy = resolvedBy;
        }

        if (resolveTimeFrom) {
            this.resolveTimeFrom = resolveTimeFrom;
        }

        if (resolveTimeTo) {
            this.resolveTimeTo = resolveTimeTo;
        }

        if (allocatedTo) {
            this.allocatedTo = allocatedTo;
        }

        if (billed) {
            this.billed = billed;
        }
    }
}
