import {InvalidActivityTypeException} from "../exceptions/invalid-activity-type.exception";

export const ActivityType = {
    ALL: "ALL",
    COMMENT: "COMMENT",
    UPDATE: "UPDATE",
}

export function ActivityTypeLabel(activityType) {
    switch (activityType) {
        case ActivityType.ALL:
            return "Alles";
        case ActivityType.COMMENT:
            return "Opmerkingen";
        case ActivityType.UPDATE:
            return "Updates";
        default:
            throw new InvalidActivityTypeException(activityType);
    }
}
