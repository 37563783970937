import React, {useEffect, useState} from 'react';
import "./Form.css";
import Button from "../Button/Button";
import Style from "../../shared/data/models/style.model";
import {Theme} from "../../shared/enums/theme.enum";
import Loading from "../../pages/Loading/Loading";
import {Position} from "../../shared/enums/position.enum";
import Errors from "../Errors/Errors";
import parseException from "../../shared/util/parse-exception";

function Form(
    {
        handleSubmit,
        disabled = false,
        useLoader = false,
        inputs,
        className,
        buttonText = "Versturen",
        icon,
        warning = null,
        info = null,
        extraButtons,
        extraButtonsPosition = Position.RIGHT,
        setExternalSubmitting,
        children
    }
) {
    const [errors, setErrors] = useState([]);

    const [submitting, setSubmitting] = useState(false);
    useEffect(() => {
        if (setExternalSubmitting) {
            setExternalSubmitting(submitting);
        }
    }, [submitting]);

    async function handle(e) {
        setErrors([]);
        setSubmitting(true);
        e.preventDefault();

        try {
            await handleSubmit();
        } catch (e) {
            parseException(e, setErrors, "", "Er is een onbekende fout opgetreden. Probeer het later opnieuw.");
        } finally {
            setSubmitting(false);
        }
    }

    return (
        <>
            <form onSubmit={handle} className={className}>
                <Errors errors={errors}/>

                {
                    inputs && inputs.length > 0 ? (
                        // Inspired by: https://stackoverflow.com/a/32371612
                        inputs.map((input, index) => {
                            if (React.isValidElement(input)) {
                                let key = input.key || input.name || index;
                                return React.cloneElement(input, {key: key, disabled: submitting || disabled});
                            }
                            return input;
                        })
                    ) : null
                }

                {children}

                {
                    warning ?
                        (
                            <div className="form-warning">
                                {warning}
                            </div>
                        )
                        : null
                }

                {
                    info ?
                        (
                            <div className="form-info">
                                {info}
                            </div>
                        )
                        : null
                }

                <div className="form-buttons">
                    {extraButtonsPosition === Position.LEFT ? extraButtons : null}
                    <Button
                        className="submit-button"
                        style={new Style(Theme.PRIMARY)} icon={icon}
                        args={{type: "submit", disabled: submitting || disabled}}>
                        {buttonText}
                    </Button>
                    {extraButtonsPosition === Position.RIGHT ? extraButtons : null}
                </div>
            </form>

            {useLoader && submitting ? <Loading showOverlay={true}/> : null}
        </>
    )
}

export default Form
