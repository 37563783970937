export default class DateFacade {
    static #minimum_date = new Date("1990-01-01T00:00:00");
    #date;

    constructor(date) {
        const dateObject = new Date(date);
        if (!!date && dateObject instanceof Date && dateObject > DateFacade.#minimum_date) {
            this.#date = dateObject;
        }
    }

    hasDate() {
        return !!this.#date;
    }

    readableDate() {
        if (!this.hasDate()) {
            return null;
        }

        return this.#date_format() + " " + this.#time_format();
    }

    epoch() {
        if (!this.hasDate()) {
            return null;
        }

        return this.#date.getTime();
    }

    toString() {
        if (!this.hasDate()) {
            return null;
        }

        let day = this.#format_two_digits(this.#date.getDate());
        let month = this.#format_two_digits(this.#date.getMonth() + 1);
        let year = this.#date.getFullYear();

        let hours = this.#format_two_digits(this.#date.getHours());
        let minutes = this.#format_two_digits(this.#date.getMinutes());
        let seconds = this.#format_two_digits(this.#date.getSeconds());

        return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
    }

    #date_format() {
        let day = this.#date.getDate();
        let month = this.#date.toLocaleString('default', {month: 'long'});
        let year = this.#date.getFullYear();

        if (day === new Date().getDate() && month === new Date().toLocaleString('default', {month: 'long'})) {
            return "Vandaag";
        }

        if (year === new Date().getFullYear()) {
            return day + " " + month;
        }

        return day + " " + month + " " + year;
    }

    // Copied and adjusted from: https://stackoverflow.com/a/19346876
    #time_format() {
        let hours = this.#format_two_digits(this.#date.getHours());
        let minutes = this.#format_two_digits(this.#date.getMinutes());

        return hours + ":" + minutes;
    }

    // Copied from: https://stackoverflow.com/a/19346876
    #format_two_digits(n) {
        return n < 10 ? '0' + n : n;
    }
}
