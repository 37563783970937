import React from 'react';
import "./Card.css";

function Card
(
    {
        children,
        id,
        className = "",
        onClick
    }
) {
    return (
        <article id={id} className={"card " + className} onClick={onClick}>
            {children}
        </article>
    )
}

export default Card;
