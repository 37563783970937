import React, {useContext} from "react";
import {ValuesContext} from "../../../shared/context/ValuesContext";
import ProblemIssueApiValue from "./ApiValue/ProblemIssueApiValue";

function ProblemIssueApiValues(
    {
        disabled = true,
        skipNextReset = false,
        required = false,
        company: [company, setCompany],
        store: [store, setStore],
        equipment: [equipment, setEquipment],
        application: [application, setApplication],
        priority: [priority, setPriority],
    }
) {
    const {companyValues, storeValues, equipmentValues, applicationValues, priorityValues} = useContext(ValuesContext);

    return (
        <div>
            <ProblemIssueApiValue name="company"
                                  label="Bedrijf"
                                  disabled={disabled}
                                  skipNextReset={skipNextReset}
                                  apiValues={companyValues}
                                  state={[company, setCompany]}
                                  required={required}/>

            <ProblemIssueApiValue name="store"
                                  label="Winkel"
                                  disabled={disabled}
                                  skipNextReset={skipNextReset}
                                  apiValues={storeValues}
                                  state={[store, setStore]}
                                  deps={[company]}
                                  required={required}/>

            <ProblemIssueApiValue name="equipment"
                                  label="Onderdeel"
                                  disabled={disabled}
                                  skipNextReset={skipNextReset}
                                  apiValues={equipmentValues}
                                  state={[equipment, setEquipment]}
                                  deps={[company]}
                                  required={required}/>

            <ProblemIssueApiValue name="application"
                                  label="Applicatie"
                                  disabled={disabled}
                                  skipNextReset={skipNextReset}
                                  apiValues={applicationValues}
                                  state={[application, setApplication]}
                                  deps={[company, equipment]}
                                  required={required}/>

            <ProblemIssueApiValue name="priority"
                                  label="Prioriteit"
                                  disabled={disabled}
                                  skipNextReset={skipNextReset}
                                  apiValues={priorityValues}
                                  state={[priority, setPriority]}
                                  deps={[company]}
                                  required={required}/>
        </div>
    );
}

export default ProblemIssueApiValues;
