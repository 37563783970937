import {ProblemIssuePaginationSize} from "../../../../enums/problem-issue-pagination-size.enum";

export default class ProblemIssuePaginationParams {
    after;
    size;

    constructor(size = ProblemIssuePaginationSize.REGULAR, after = null) {
        this.size = size;

        if (after !== null) {
            this.after = after;
        }
    }
}
