import React, {useContext, useState} from 'react';
import {AuthContext} from "../../shared/context/AuthContext";
import {UserService} from "../../shared/services/http-services/user.service";
import Form from "../../components/Form/Form";
import Input from "../../components/Form/Input/Input";
import UnauthenticatedLayout from "../../components/Layout/UnauthenticatedLayout/UnauthenticatedLayout";
import {Link} from "react-router-dom";

function Login() {
    const authContext = useContext(AuthContext);
    const {authenticate} = authContext;

    const userService = new UserService(authContext);

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    async function login() {
        const token = await userService.login(username, password);
        await authenticate(token);
    }

    return (
        <UnauthenticatedLayout title="Login">
            <Form handleSubmit={login} useLoader={true} inputs={
                [
                    <Input key="username"
                           nameAttribute="username"
                           labelText="Shortname"
                           state={[username, setUsername]}
                           required={true}/>,
                    <Input key="password"
                           nameAttribute="password"
                           inputType="password"
                           labelText="Wachtwoord"
                           state={[password, setPassword]}
                           required={true}/>,
                ]
            } info={
                <p>
                    Nog geen account? <Link to="/register">Registreer</Link> nu!
                </p>
            }/>
        </UnauthenticatedLayout>
    );
}

export default Login
