export default class Activity {
    id;
    origin;
    dateTime;
    user;
    message;
    type;

    constructor() {
        this.id = crypto.randomUUID();
    }
}
