import "./Popup.css";
import {useEffect} from "react";
import Button from "../Button/Button";
import {IconType} from "../../shared/enums/icon-type.enum";
import Loading from "../../pages/Loading/Loading";
import Style from "../../shared/data/models/style.model";
import {Theme} from "../../shared/enums/theme.enum";
import {Appearance} from "../../shared/enums/appearance.enum";

function Popup(
    {
        children,
        closePopup,
        title = "",
        fullscreen = false,
        processing = false,
    }
) {
    // Disable scrolling when popup is open
    useEffect(() => {
        let overflow = document.body.style.overflow;
        document.body.style.overflow = "hidden";

        return () => {
            document.body.style.overflow = overflow;
        }
    }, []);

    async function onClose() {
        if (processing) {
            return;
        }

        await closePopup();
    }

    return (
        <>
            <div className={"popup-overlay " + (processing ? "processing" : "")} onClick={onClose}/>
            <section className={"popup " + (fullscreen ? "fullscreen" : "")}>
                {
                    processing ? <Loading showOverlay={true}/> : null
                }

                <header>
                    <h2>{title}</h2>
                    <div className="btn-container">
                        <Button style={new Style(Theme.DARK, Appearance.OUTLINE)} icon={IconType.XMARK}
                                args={{onClick: onClose}}/>
                    </div>
                </header>
                <main>
                    {children}
                </main>
            </section>
        </>
    )
}

export default Popup;
