import Activity from "../models/activity.model";
import DateFacade from "../models/date-facade.model";
import {ActivityType} from "../../enums/activity-type.enum";
import {DescriptionType} from "../../enums/description-type.enum";

export default class ActivitySerializer {
    fromJsonArray(jsonArray) {
        try {
            if (jsonArray.length > 0) {
                return jsonArray.map(json => this.fromJson(json));
            }
        } catch (e) {
            // Do nothing
        }

        return [];
    }

    fromJson(json) {
        const activity = new Activity();
        activity.origin = this.fromOrigin(json.origin);
        activity.dateTime = new DateFacade(json.dateTime);
        activity.user = json.user;
        activity.message = json.message;
        activity.type = ActivityType.COMMENT;

        return activity;
    }

    toJsonArray(activities) {
        try {
            if (activities.length > 0) {
                return activities.map(activity => this.toJson(activity)).filter(message => message);
            }
        } catch (e) {
            // Do nothing
        }

        return [];
    }

    toJson(activity) {
        if (activity.type !== ActivityType.COMMENT) {
            return null;
        }

        return {
            origin: this.toOrigin(activity.origin),
            dateTime: activity.dateTime?.toString(),
            user: activity.user,
            message: activity.message
        };
    }

    fromOrigin(origin) {
        switch (origin) {
            case "Description":
                return DescriptionType.REGULAR;
            case "SolutionDescription":
                return DescriptionType.SOLUTION;
            case "SiteCommunication":
                return DescriptionType.SITE_COMMUNICATION;
            default:
                return null;
        }
    }

    toOrigin(origin) {
        switch (origin) {
            case DescriptionType.REGULAR:
                return "Description";
            case DescriptionType.SOLUTION:
                return "SolutionDescription";
            case DescriptionType.SITE_COMMUNICATION:
                return "SiteCommunication";
            default:
                return "Unknown";
        }
    }
}
