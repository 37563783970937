import React, {useContext} from 'react';
import {AuthContext} from "./shared/context/AuthContext";
import {Routes, Route, Navigate, useLocation} from "react-router-dom";
import Home from "./pages/Home/Home";
import Login from "./pages/Login/Login";
import Register from "./pages/Register/Register";
import Profile from "./pages/Profile/Profile";
import ProblemIssueDetails from "./pages/ProblemIssue/Details/Details";
import ProblemIssueCreate from "./pages/ProblemIssue/Create/Create";
import ProblemIssueEdit from "./pages/ProblemIssue/Edit/Edit";
import Logout from "./pages/Logout/Logout";
import Account from "./pages/Account/Account";
import Users from "./pages/Users/Users";
import AccountComplete from "./pages/Account/AccountComplete/AccountComplete";
import RegisterSuccess from "./pages/Register/Success/RegisterSuccess";

function App() {
    const location = useLocation();
    const {user, isAuthenticated, isAdmin, isDeveloper} = useContext(AuthContext);

    return (
        <>
            <Routes>
                <Route path="/logout" element={isAuthenticated ? <Logout/> : <Navigate to="/login"/>}/>
                {
                    // Redirect to complete account if needed
                    isAuthenticated && user.needsCompletion() ? (
                        <>
                            <Route path="/account/complete" element={<AccountComplete/>}/>
                            <Route path="*"
                                   element={<Navigate to="/account/complete" state={{redirect: location.pathname}}/>}/>
                        </>
                    ) : (
                        <>
                            <Route path="/" element={isAuthenticated ? <Home/> : <Navigate to="/login"/>}/>
                            <Route path="/login" element={!isAuthenticated ? <Login/> : <Navigate to="/"/>}/>
                            <Route path="/register" element={!isAuthenticated ? <Register/> : <Navigate to="/"/>}/>
                            <Route path="/register/success"
                                   element={!isAuthenticated ? <RegisterSuccess/> : <Navigate to="/"/>}/>
                            <Route path="/account" element={isAuthenticated ? <Account/> :
                                <Navigate to="/login" state={{redirect: location.pathname}}/>}/>
                            <Route path="/profile/:id" element={isAuthenticated ? <Profile/> :
                                <Navigate to="/login" state={{redirect: location.pathname}}/>}/>
                            <Route path="/issue/create"
                                   element={isAuthenticated ? (isDeveloper ? <ProblemIssueCreate/> :
                                       <Navigate to="/"/>) : <Navigate to="/login"/>}/>
                            <Route path="/issue/details/:id" element={isAuthenticated ? <ProblemIssueDetails/> :
                                <Navigate to="/login" state={{redirect: location.pathname}}/>}/>
                            <Route path="/issue/edit/:id"
                                   element={isAuthenticated ? (isDeveloper ? <ProblemIssueEdit/> : <Navigate to="/"/>) :
                                       <Navigate to="/login"
                                                 state={{redirect: location.pathname.replace("edit", "details")}}/>}/>
                            <Route path="/users" element={isAuthenticated ? (isAdmin ? <Users/> : <Navigate to="/"/>) :
                                <Navigate to="/login" state={{redirect: location.pathname}}/>}/>
                            <Route path="*" element={<Navigate to="/"/>}/>
                        </>
                    )
                }
            </Routes>
        </>
    );
}

export default App;
