import "./Button.css";
import {ButtonType} from "../../shared/enums/button-type.enum";
import RegularButton from "./RegularButton/RegularButton";
import DisablingButton from "./DisablingButton/DisablingButton";
import LinkButton from "./LinkButton/LinkButton";
import {Position} from "../../shared/enums/position.enum";
import Icon from "../Icon/Icon";
import Style from "../../shared/data/models/style.model";

function Button
(
    {
        btnType = ButtonType.REGULAR,
        style = new Style(),
        iconPosition = Position.RIGHT,
        title,
        icon,
        className,
        args,
        children,
    }
) {
    className = style.getClassName("btn") + " " + className;

    // Add icon
    let content = children;
    if (icon) {
        icon = <Icon icon={icon}/>;
        if (iconPosition === Position.LEFT) {
            content = (
                <>
                    {icon}
                    {content}
                </>
            );
        } else if (iconPosition === Position.RIGHT) {
            content = (
                <>
                    {content}
                    {icon}
                </>
            );
        }
    }

    switch (btnType) {
        default:
        case ButtonType.REGULAR:
            return (
                <RegularButton title={title} className={className} {...args}>
                    {content}
                </RegularButton>
            );
        case ButtonType.LINK:
            return (
                <LinkButton title={title} className={className} {...args}>
                    {content}
                </LinkButton>
            );
        case ButtonType.DISABLING:
            return (
                <DisablingButton title={title} className={className} {...args}>
                    {content}
                </DisablingButton>
            );
    }
}

export default Button;
