import Panel from "../../Panel/Panel";
import React from "react";

function FormPanel(
    {
        collapsable = true,
        initialCollapsed = true,
        disabled = true,
        title,
        inputs,
    }
) {
    return (
        <Panel
            collapsable={collapsable}
            initialCollapsed={initialCollapsed}
            disabled={disabled}
            title={title}>
            {
                inputs && inputs.length > 0 ? (
                    // Inspired by: https://stackoverflow.com/a/32371612
                    inputs.map((input, index) => {
                        if (React.isValidElement(input)) {
                            let key = input.key || input.name || index;
                            return React.cloneElement(input, {key: key, disabled: disabled});
                        }
                        return input;
                    })
                ) : null
            }
        </Panel>
    );
}

export default FormPanel;
