import Descriptor from "./descriptor.model";
import {Urgency} from "../../enums/urgency.enum";
import {Appearance} from "../../enums/appearance.enum";
import {Theme} from "../../enums/theme.enum";
import Style from "./style.model";

export default class Priority extends Descriptor {
    urgency;
    isVisible;
    showId;
    style;

    constructor(obj) {
        super(obj);

        this.urgency = this.#urgency();
        this.isVisible = this.#isVisible();
        this.showId = this.#showId();
        this.style = this.#style();
    }

    #isVisible() {
        return this.#urgency() !== Urgency.UNKNOWN;
    }

    #showId() {
        return this.#urgency() !== Urgency.UNKNOWN && this.#urgency() !== Urgency.OTHER;
    }

    #urgency() {
        switch (this.description) {
            case "Low":
            case "Laag":
                return Urgency.LOW;
            case "Normal":
            case "Normaal":
                return Urgency.NORMAL;
            case "High":
            case "Hoog":
                return Urgency.HIGH;
            case "Blocking":
            case "Blokkerend":
                return Urgency.BLOCKING;
            case "Depricated":
            case "Unknown":
            case "Onbekend":
                return Urgency.UNKNOWN;
            default:
                return Urgency.OTHER;
        }
    }

    #style() {
        let theme;
        let appearance = Appearance.REGULAR;

        switch (this.urgency) {
            case Urgency.LOW:
                theme = Theme.SUCCESS;
                break;
            case Urgency.NORMAL:
                theme = Theme.WARNING;
                break;
            case Urgency.HIGH:
                theme = Theme.DANGER;
                break;
            case Urgency.BLOCKING:
                theme = Theme.DANGER;
                break;
            case Urgency.UNKNOWN:
            case Urgency.OTHER:
            default:
                theme = Theme.INFO;
                appearance = Appearance.OUTLINE;
                break;

        }

        return new Style(theme, appearance);
    }
}
