import {HttpService} from "./http.service";
import {HttpMethod} from "../../enums/http-method.enum";
import AccountSerializer from "../../data/serializers/account.serializer";

export class AccountService extends HttpService {

    constructor(authContext) {
        super(authContext, "Account", new AccountSerializer());
    }

    async account() {
        const response = await this.request("", HttpMethod.GET);

        if (response) {
            try {
                return this.serializer.fromJson(response.data);
            } catch (e) {
                // Do nothing
            }
        }

        return null;
    }

    async update(firstName, lastName, email) {
        const data = {
            firstName: firstName,
            lastName: lastName,
            email: email
        }

        const response = await this.request("Update", HttpMethod.PUT, data);
        return !!response;
    }

    async changePassword(oldPassword, newPassword, confirmPassword) {
        const data = {
            oldPassword: oldPassword,
            newPassword: newPassword,
            confirmPassword: confirmPassword
        }

        const response = await this.request("ChangePassword", HttpMethod.PUT, data);
        return !!response;
    }
}
