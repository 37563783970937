import {UserService} from "../../shared/services/http-services/user.service";
import {useData} from "../../shared/util/custom-hooks/use-data";
import {AuthContext} from "../../shared/context/AuthContext";
import React, {useContext} from "react";
import Card from "../Card/Card";
import ApproveUser from "./ApproveUser/ApproveUser";
import Loading from "../../pages/Loading/Loading";
import {DataStatus} from "../../shared/enums/data-status.enum";
import "./ApproveUsers.css";
import PageException from "../Errors/PageException/PageException";

function ApproveUsers() {
    const authContext = useContext(AuthContext);

    // Load data
    const userService = new UserService(authContext);
    const [users, dataStatus, reloadData, , exception] = useData(() => userService.users(), []);

    if (dataStatus !== DataStatus.DONE && dataStatus !== DataStatus.ERROR) {
        return <Loading/>;
    }

    if (dataStatus === DataStatus.ERROR) {
        return (
            <PageException dataStatus={dataStatus}
                           query={async () => {
                               await reloadData();
                           }}
                           itemName={"Gebruikers"}
                           isPlural={true}
                           exception={exception}/>
        );
    }

    return (
        <Card className="approve-users">
            <div className="approve-users-header"></div>
            <div className="approve-users-header">ID</div>
            <div className="approve-users-header">Volledige naam</div>
            <div className="approve-users-header">Voornaam</div>
            <div className="approve-users-header">Achternaam</div>
            <div className="approve-users-header">E-Mail</div>

            {
                users.map((user, index) =>
                    <ApproveUser key={user.id}
                                 user={user} reloadData={reloadData}
                                 className={index % 2 === 0 ? "approve-users-even" : "approve-users-uneven"}/>
                )
            }
        </Card>
    );
}

export default ApproveUsers;
