function RegularInput(
    {
        className,
        nameAttribute,
        idAttribute,
        placeHolder,
        disabled,
        state: [stateValue, stateSetter],
        required = false,
        min = null,
        max = null,
        step = null,
        maxDigits = null,
        maxDecimals = null,
    }
) {
    function onChange(e) {
        const value = e.target.value;

        if (maxDigits !== null) {
            const digits = value.toString().split(".")[0].length;
            if (digits > maxDigits) {
                return;
            }
        }

        if (maxDecimals !== null) {
            const decimals = value.toString().split(".")[1]?.length ?? 0;
            if (decimals > maxDecimals) {
                return;
            }
        }

        stateSetter(value);
    }

    return (
        <input
            className={className}
            type="number"
            name={nameAttribute}
            value={stateValue}
            placeholder={placeHolder}
            disabled={disabled}
            onChange={onChange}
            id={idAttribute}
            required={required}
            min={min}
            max={max}
            step={step}/>
    )
}

export default RegularInput
