import {DataStatus} from "../../enums/data-status.enum";
import {arraysEqual} from "../../util/array";
import Semaphore from "../../util/semaphore/semaphore";
import parseException from "../../util/parse-exception";

export default class ApiValue {
    #semaphore;
    #requestingWithArgs;

    state;
    #stateSetter;
    status;
    #statusSetter;
    #previousArgs;
    #previousArgsSetter;
    #request;

    #errorsSetter;
    #errorHeaderSetter;
    #errorHeader;

    #initial;

    constructor([state, stateSetter], [status, statusSetter], [previousArgs, previousArgsSetter], request, [errorsSetter, errorHeaderSetter, errorHeader], initial = []) {
        this.#semaphore = new Semaphore({resources: 1, start: 1});
        this.#requestingWithArgs = null;

        this.state = state;
        this.#stateSetter = stateSetter;
        this.status = status;
        this.#statusSetter = statusSetter;
        this.#previousArgs = previousArgs;
        this.#previousArgsSetter = previousArgsSetter;
        this.#request = request;

        this.#errorsSetter = errorsSetter;
        this.#errorHeaderSetter = errorHeaderSetter;
        this.#errorHeader = errorHeader;

        this.#initial = initial;
    }

    async execute(args) {
        // Check if currently requesting with the same args
        if (this.#requestingWithArgs && arraysEqual(args, this.#requestingWithArgs)) {
            return;
        }

        this.#requestingWithArgs = args;
        await this.#semaphore.acquire();
        try {
            await this.#execute(args);
        } finally {
            this.#requestingWithArgs = null;
            this.#semaphore.release();
        }
    }

    async #execute(args) {
        if (this.state && this.state.length > 0 && arraysEqual(args, this.#previousArgs)) {
            // Already loaded
            return;
        }

        this.#statusSetter(DataStatus.PENDING);
        try {
            const response = await this.#executeRequest(args);
            if (!response) {
                this.#stateSetter(this.#initial);
                return;
            }

            this.#stateSetter(response);
            this.#previousArgsSetter(args);
        } catch (e) {
            this.#stateSetter(this.#initial);
            this.#statusSetter(DataStatus.ERROR);
            return;
        }

        this.#statusSetter(DataStatus.DONE);
    }

    async #executeRequest(args) {
        try {
            const response = await this.#request(...args);

            if (response) {
                return response;
            }
        } catch (e) {
            parseException(e, this.#errorsSetter);
            this.#errorHeaderSetter(this.#errorHeader);

            throw e;
        }

        return this.#initial;
    }
}
