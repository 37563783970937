import AuthenticatedLayout from "../../components/Layout/AuthenticatedLayout/AuthenticatedLayout";
import Card from "../../components/Card/Card";
import Form from "../../components/Form/Form";
import Input from "../../components/Form/Input/Input";
import {AuthContext} from "../../shared/context/AuthContext";
import {useContext, useState} from "react";
import "./Account.css";
import {AccountService} from "../../shared/services/http-services/account.service";
import Button from "../../components/Button/Button";
import {IconType} from "../../shared/enums/icon-type.enum";
import {ButtonType} from "../../shared/enums/button-type.enum";
import Style from "../../shared/data/models/style.model";
import {Theme} from "../../shared/enums/theme.enum";
import {Appearance} from "../../shared/enums/appearance.enum";
import {useLocation} from "react-router-dom";
import AccountForm from "../../components/Form/Account/AccountForm";
import {SuccessContext} from "../../shared/context/SuccessContext";

function Account() {
    const location = useLocation();
    const {showSuccess} = useContext(SuccessContext);
    const authContext = useContext(AuthContext);
    const {user} = authContext;
    const accountService = new AccountService(authContext);

    const [oldPassword, setOldPassword] = useState();
    const [newPassword, setNewPassword] = useState();
    const [confirmPassword, setConfirmPassword] = useState();

    async function changePassword() {
        const success = await accountService.changePassword(oldPassword, newPassword, confirmPassword);
        if (success) {
            setOldPassword("");
            setNewPassword("");
            setConfirmPassword("");

            showSuccessMessage("Wachtwoord");
        }
    }

    function showSuccessMessage(item) {
        showSuccess(`${item} is bijgewerkt!`);
    }

    return (
        <AuthenticatedLayout title="Account"
                             pageButtons={
                                 <Button icon={IconType.CHEVRON_RIGHT} btnType={ButtonType.LINK}
                                         style={new Style(Theme.PRIMARY, Appearance.OUTLINE)}
                                         args={{to: "/profile/" + user.id, state: {from: location.pathname}}}>
                                     Bekijk profiel
                                 </Button>
                             }>
            <h2>Gegevens</h2>
            <Card className="account-update-user">
                <AccountForm onSuccessfulUpdate={() => showSuccessMessage("Account")}/>
            </Card>

            <h2>Wachtwoord wijzigen</h2>
            <Card>
                <Form buttonText="Opslaan" handleSubmit={changePassword} useLoader={true} inputs={
                    [
                        <Input key="oldPassword"
                               nameAttribute="oldPassword"
                               inputType="password"
                               labelText="Oud wachtwoord"
                               state={[oldPassword, setOldPassword]}
                               required={true}
                               maxlength={30}/>,
                        <Input key="newPassword"
                               nameAttribute="newPassword"
                               inputType="password"
                               labelText="Nieuw wachtwoord"
                               state={[newPassword, setNewPassword]}
                               required={true}
                               maxlength={30}/>,
                        <Input key="confirmPassword"
                               nameAttribute="confirmPassword"
                               inputType="password"
                               labelText="Bevestig wachtwoord"
                               state={[confirmPassword, setConfirmPassword]}
                               required={true}
                               maxlength={30}/>
                    ]
                }/>
            </Card>
        </AuthenticatedLayout>
    )
}

export default Account;
