import {useLocation} from "react-router-dom";
import {useContext, useEffect, useState} from "react";
import {useLocalStoredState} from "../../shared/util/custom-hooks/use-local-stored-state";
import {QuickFilterType} from "../../shared/enums/quick-filter-type.enum";
import ProblemIssueSearchParams
    from "../../shared/data/models/problem-issue/params/search/problem-issue-search-params.model";
import {ProblemIssueSearchType} from "../../shared/enums/problem-issue-search-type.enum";
import AuthenticatedLayout from "../../components/Layout/AuthenticatedLayout/AuthenticatedLayout";
import {AsideMobileStyle} from "../../shared/enums/aside-mobile-style.enum";
import Card from "../../components/Card/Card";
import AdvancedSearch from "../../components/ProblemIssue/Search/Advanced/AdvancedSearch";
import Button from "../../components/Button/Button";
import {ButtonType} from "../../shared/enums/button-type.enum";
import Style from "../../shared/data/models/style.model";
import {Theme} from "../../shared/enums/theme.enum";
import {Appearance} from "../../shared/enums/appearance.enum";
import {IconType} from "../../shared/enums/icon-type.enum";
import QuickFilters from "../../components/ProblemIssue/QuickFilters/QuickFilters";
import ProblemIssues from "../../components/ProblemIssue/ProblemIssues/ProblemIssues";
import QuickFilter from "../../shared/data/models/quick-filter.model";
import {AuthContext} from "../../shared/context/AuthContext";
import {useMediaQuery} from "../../shared/util/custom-hooks/use-media-query";
import "./Home.css";

function Home() {
    const {isDeveloper} = useContext(AuthContext);
    const smallScreen = useMediaQuery('(max-width: 991px)');
    const location = useLocation();

    // Disabled
    const [disabled, setDisabled] = useState(true);

    // Selected QuickFilter
    const [quickFilter, setQuickFilter] = useLocalStoredState("quickFilter", new QuickFilter(), location.state?.reset);

    // Search
    const [searchParams, setSearchParams] = useLocalStoredState("searchParams", new ProblemIssueSearchParams(), location.state?.reset);
    const [searchType, setSearchType] = useState(searchParams.type === ProblemIssueSearchType.NONE ? ProblemIssueSearchType.BASIC : searchParams.type);

    // Advanced Search Visible
    const [advancedSearchVisible, setAdvancedSearchVisible] = useState(smallScreen ? false : searchType === ProblemIssueSearchType.ADVANCED);
    useEffect(() => {
        if(!smallScreen) {
            setAdvancedSearchVisible(searchType === ProblemIssueSearchType.ADVANCED);
            return;
        }

        // When screen is small:
        // Reset search type if search params are empty
        if(searchType === ProblemIssueSearchType.ADVANCED && searchParams && searchParams.type === ProblemIssueSearchType.NONE) {
            resetSearch();
        }
        // Otherwise hide advanced search
        else {
            setAdvancedSearchVisible(false);
        }
    }, [smallScreen]);

    function openAdvancedSearch() {
        setSearchType(ProblemIssueSearchType.ADVANCED);
        setAdvancedSearchVisible(true);
    }

    function resetSearch() {
        setSearchParams(new ProblemIssueSearchParams());
        setSearchType(ProblemIssueSearchType.BASIC);
        setAdvancedSearchVisible(false);
    }

    return (
        <AuthenticatedLayout title="Problem Issues" asideMobileStyle={AsideMobileStyle.POPUP}
                             closeAside={() => setAdvancedSearchVisible(false)}
                             asideVisible={advancedSearchVisible}
                             asideSticky={true}
                             aside={
                                 <Card className="advanced-search">
                                     <AdvancedSearch
                                         disabled={disabled}
                                         searchParams={[searchParams, setSearchParams]}
                                         advancedSearchVisible={[advancedSearchVisible, setAdvancedSearchVisible]}
                                         resetSearch={resetSearch}/>
                                 </Card>
                             }
                             pageButtonsVisible={isDeveloper}
                             pageButtons={
                                 <Button btnType={ButtonType.LINK}
                                         style={new Style(Theme.SECONDARY, Appearance.OUTLINE)}
                                         icon={IconType.PLUS}
                                         args={{to: "/issue/create"}}>
                                     PIW Aanmaken
                                 </Button>
                             }
                             contentHeader={
                                 <QuickFilters quickFilter={[quickFilter, setQuickFilter]}
                                               disabled={disabled}
                                               quickFilters={[QuickFilterType.ALL, QuickFilterType.NOTASSIGNED, QuickFilterType.ASSIGNED, QuickFilterType.ASSIGNEDTOME, QuickFilterType.REPORTEDBYME, QuickFilterType.CLOSED]}/>
                             }>
            <ProblemIssues interactive={true}
                           disabled={[disabled, setDisabled]}
                           searchParams={[searchParams, setSearchParams]}
                           searchType={[searchType, setSearchType]}
                           quickFilter={quickFilter}
                           advancedSearchVisible={advancedSearchVisible}
                           openAdvancedSearch={openAdvancedSearch}
                           resetSearch={resetSearch}/>
        </AuthenticatedLayout>
    );
}

export default Home
