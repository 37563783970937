import React, {useContext, useEffect, useRef, useState} from "react";
import {ProblemIssueService} from "../../../shared/services/http-services/problem-issue.service";
import {useLocalStoredState} from "../../../shared/util/custom-hooks/use-local-stored-state";
import ProblemIssueOrderParams from "../../../shared/data/models/problem-issue/params/problem-issue-order-params.model";
import Spinner from "../../Spinner/Spinner";
import {useData} from "../../../shared/util/custom-hooks/use-data";
import ProblemIssuePaginationParams
    from "../../../shared/data/models/problem-issue/params/problem-issue-pagination-params.model";
import {ProblemIssueOrderBy, ProblemIssueOrderByLabel} from "../../../shared/enums/problem-issue-order-by.enum";
import {ProblemIssuePaginationSize} from "../../../shared/enums/problem-issue-pagination-size.enum";
import Input from "../../Form/Input/Input";
import {DataStatus} from "../../../shared/enums/data-status.enum";
import {ProblemIssueSearchType} from "../../../shared/enums/problem-issue-search-type.enum";
import {AuthContext} from "../../../shared/context/AuthContext";
import throttle from "../../../shared/util/throttle";
import BasicSearch from "../Search/Basic/BasicSearch";
import ProblemIssue from "./ProblemIssue/ProblemIssue";
import {useLocation, useNavigate} from "react-router-dom";
import Card from "../../Card/Card";
import "./ProblemIssues.css";
import {QuickFilterType} from "../../../shared/enums/quick-filter-type.enum";
import Button from "../../Button/Button";
import {IconType} from "../../../shared/enums/icon-type.enum";
import Style from "../../../shared/data/models/style.model";
import {Theme} from "../../../shared/enums/theme.enum";
import {Appearance} from "../../../shared/enums/appearance.enum";
import PageException from "../../Errors/PageException/PageException";

function ProblemIssues(
    {
        interactive = true,
        disabled: [disabled, setDisabled] = [true, (newDisabled) => {
        }],
        searchParams: [searchParams, setSearchParams] = [undefined, (newSearchParams) => {
        }],
        searchType: [searchType, setSearchType] = [undefined, (newSearchType) => {
        }],
        quickFilter,
        advancedSearchVisible = false,
        openAdvancedSearch = () => {
        },
        resetSearch = () => {
        },
    }
) {
    const navigate = useNavigate();
    const location = useLocation();
    const overviewItemListRef = useRef();

    // Order
    const [orderBy, setOrderBy] = useLocalStoredState("orderBy", ProblemIssueOrderBy.UpdatedOn, location.state?.reset, interactive);
    const [reverse, setReverse] = useLocalStoredState("reverseOrder", "true", location.state?.reset, interactive);

    // Pagination
    const [size, , clearSize, setLocalSize] = useLocalStoredState("paginationSize", ProblemIssuePaginationSize.REGULAR, location.state?.reset || location.state?.resetSelectedIssue, interactive);
    const [afterCursor, setAfterCursor] = useState(undefined);
    const [hasNext, setHasNext] = useState(true);

    // Issues
    const authContext = useContext(AuthContext);
    const issueService = new ProblemIssueService(authContext);
    const [issues, setIssues] = useState([]);

    // Selected Issue
    const [selectedIssue, setSelectedIssue, , setLocalSelectedIssue] = useLocalStoredState("selectedIssue", null, location.state?.reset || location.state?.resetSelectedIssue, interactive);
    const [searchingSelectedIssue, setSearchingSelectedIssue] = useState(selectedIssue ? DataStatus.PENDING : DataStatus.DONE);
    useEffect(() => {
        // Scroll to selected issue
        if (searchingSelectedIssue === DataStatus.DONE) {
            const issueElement = overviewItemListRef.current?.querySelector(`#${selectedIssue}`);
            issueElement?.scrollIntoView({
                behavior: 'instant',
                block: 'center',
                inline: 'center'
            });
            issueElement?.classList.add("selected-issue")

            setSelectedIssue(null);
        }
    }, [searchingSelectedIssue])

    // Query issues
    const [getIssues, setGetIssues] = useState(() => null);
    useEffect(() => {
        // Initial load of the data
        if (dataStatus === DataStatus.INITIAL) {
            void query(DataStatus.INITIAL);
            return;
        }

            // afterCursor is null and dataStatus is not initial
            // This means the afterCursor has been deliberately set to null
        // Therefore the issues must be reset and reloaded
        else if (afterCursor === undefined) {
            setIssues([]);
            void query(DataStatus.RESETTING);
            return;
        }

        // Check if more data should be loaded to load the selected issue
        if (selectedIssue && dataStatus === DataStatus.DONE && searchingSelectedIssue === DataStatus.PENDING) {
            if (issues.map(issue => issue.id).includes(selectedIssue) || !hasNext) {
                // Issue has been found
                // Or all issues have been loaded and the issue could not be found
                setSearchingSelectedIssue(DataStatus.DONE);
            } else if (hasNext) {
                // Load more issues
                void query();
            }
        }
    }, [getIssues])

    // Sets and updates the method to gather data
    // Method can only change if a reset is requested (through afterCursor = null)
    // Or if new data has been received that has a new afterCursor
    useEffect(() => {
        async function getIssuesMethod() {
            return await issueService.getIssues(new ProblemIssueOrderParams(orderBy, reverse === "true"), new ProblemIssuePaginationParams(size, afterCursor), searchParams, quickFilter);
        }

        setGetIssues(() => getIssuesMethod);
    }, [afterCursor])

    const [data, dataStatus, queryIssues, , exception] = useData(getIssues, [[], {}]);
    // Process data
    useEffect(() => {
        let [newIssues, paginationInfo] = data;

        // Filter out issues that already al shown
        const currentIds = new Set(issues.map(issue => issue.id));
        newIssues = newIssues.filter(issue => !currentIds.has(issue.id));

        // Add new not shown queried issues
        setIssues([...issues, ...newIssues]);

        // Reset size if not regular
        if (size !== ProblemIssuePaginationSize.REGULAR) {
            clearSize();
        }

        // Set (new) cursor
        try {
            if (paginationInfo && (paginationInfo.hasNext === true || paginationInfo.hasNext === false)) {
                setHasNext(paginationInfo.hasNext);

                if (paginationInfo.afterCursor) {
                    setAfterCursor(paginationInfo.afterCursor);
                } else {
                    setAfterCursor(null);
                }
            }
        } catch (e) {
            // Do nothing
        }
    }, [data, setIssues, setAfterCursor, setHasNext]);

    // Auto redirect
    const [autoRedirect, setAutoRedirect] = useState(false);

    // Redirect to issue if interactive and only one issue is found through basic search
    useEffect(() => {
        if (interactive && autoRedirect && issues.length === 1 && !hasNext && searchType === ProblemIssueSearchType.BASIC) {
            // Navigate to details page
            navigate(`/issue/details/${issues[0].id}`, {state: {from: location.pathname}});
        }
    }, [issues]);

    // Reset auto redirect
    useEffect(() => {
        setAutoRedirect(false);
    }, [quickFilter]);

    // Requests a reset
    // Triggers for reset are: orderby, reverse, or searchParams changed
    // In these cases the issues must be entirely reloaded
    useEffect(reset, [orderBy, reverse, quickFilter, searchParams]);

    // Disabled
    useEffect(() => {
        setDisabled(searchingSelectedIssue !== DataStatus.DONE || dataStatus !== DataStatus.DONE);
    }, [searchingSelectedIssue, dataStatus]);

    // Sets the afterCursor to null to trigger a reset
    // only if inital data has been loaded to prevent double load on mount
    function reset() {
        if (dataStatus !== DataStatus.INITIAL) {
            setAfterCursor(undefined);
        }
    }

    function resetFilters() {
        resetSearch();
        setOrderBy(ProblemIssueOrderBy.UpdatedOn);
        setReverse("true");
    }

    // If there is more data to load, query it
    async function loadMore() {
        if (hasNext && dataStatus === DataStatus.DONE) {
            await query();
        }
    }

    // Executes the query to load data
    async function query(pendingStatus = DataStatus.EXPANDING) {
        await queryIssues(pendingStatus);
    }

    const onScroll = async () => {
        const scrollTop = document.documentElement.scrollTop
        const scrollHeight = document.documentElement.scrollHeight
        const clientHeight = document.documentElement.clientHeight

        if (scrollTop + clientHeight >= scrollHeight - clientHeight) {
            await loadMore();
        }
    }

    useEffect(() => {
        const throttleFunction = throttle(onScroll, 10);
        if (hasNext) {
            window.addEventListener('scroll', throttleFunction);
        }

        return () => {
            window.removeEventListener('scroll', throttleFunction);
        }
    }, [loadMore]);

    return (
        <>
            {
                interactive ? (
                    <Card className="problem-issues-filters">
                        <div className="problem-issues-search">
                            <BasicSearch
                                disabled={disabled}
                                searchParams={[searchParams, setSearchParams]}
                                searchType={[searchType, setSearchType]}
                                setAutoRedirect={setAutoRedirect}/>
                        </div>
                        <div className="problem-issues-buttons">
                            <Input nameAttribute="orderby"
                                   inputType="select"
                                   disabled={disabled}
                                   className="no-margin"
                                   state={[orderBy, setOrderBy]}
                                   options={Object.keys(ProblemIssueOrderBy).map((key) => {
                                       return {
                                           value: key, label: ProblemIssueOrderByLabel(key)
                                       }
                                   })}/>

                            <Input nameAttribute="reverse"
                                   inputType="select"
                                   disabled={disabled}
                                   className="no-margin"
                                   state={[reverse, setReverse]}
                                   options={
                                       [
                                           {
                                               value: "false", label: "Oplopend"
                                           },
                                           {
                                               value: "true", label: "Aflopend"
                                           },
                                       ]
                                   }/>

                            <Button title="Reset alle filters" icon={IconType.ARROW_ROTATE_RIGHT}
                                    style={new Style(Theme.DARK, Appearance.OUTLINE)} args={{
                                onClick: resetFilters,
                                disabled: disabled
                            }}/>

                            {
                                advancedSearchVisible ?
                                    (
                                        <Button title="Reset geavanceerd zoeken" icon={IconType.FILTER_CIRCLE_XMARK}
                                                style={new Style(Theme.DARK, Appearance.OUTLINE)}
                                                args={{
                                                    onClick: resetSearch,
                                                    disabled: disabled
                                                }}/>
                                    ) : (
                                        <Button title="Geavanceerd zoeken" icon={IconType.FILTER}
                                                style={new Style(Theme.DARK, Appearance.OUTLINE)}
                                                args={{
                                                    onClick: openAdvancedSearch,
                                                    disabled: disabled
                                                }}/>
                                    )

                            }
                        </div>
                    </Card>
                ) : null
            }

            <div>
                {/* ProblemIssues */}
                {
                    searchingSelectedIssue === DataStatus.DONE && (dataStatus === DataStatus.DONE || dataStatus === DataStatus.EXPANDING || dataStatus === DataStatus.PENDING || dataStatus === DataStatus.ERROR) ?
                        (
                            <div ref={overviewItemListRef}>
                                {
                                    issues.map((issue, index) =>
                                        <ProblemIssue
                                            key={issue.id}
                                            issue={issue}
                                            issueIndex={index}
                                            setSelectedIssue={setLocalSelectedIssue}
                                            setSize={setLocalSize}/>
                                    )
                                }
                            </div>
                        ) : null
                }

                {/* No ProblemIssues */}
                {
                    searchingSelectedIssue === DataStatus.DONE && dataStatus === DataStatus.DONE && issues.length === 0 && !hasNext ?
                        (
                            <div className="no-issues">
                                <p>
                                    Geen problem issues gevonden
                                    {quickFilter && quickFilter.type !== QuickFilterType.ALL ? " voor het gekozen filter" : null}
                                    {searchParams && searchParams.type !== ProblemIssueSearchType.NONE ? " met de huidge zoekopdracht" : null}
                                </p>
                            </div>
                        ) : null
                }

                {/* Loading more ProblemIssues Spinner */}
                {
                    (searchingSelectedIssue === DataStatus.PENDING || dataStatus === DataStatus.INITIAL || dataStatus === DataStatus.EXPANDING || dataStatus === DataStatus.RESETTING || dataStatus === DataStatus.PENDING) && dataStatus !== DataStatus.ERROR ?
                        (
                            <div className="loading-more-issues-indicator">
                                <Spinner/>
                            </div>
                        ) : null
                }

                {/* Load more button if auto load more did not trigger */}
                {
                    searchingSelectedIssue === DataStatus.DONE && dataStatus === DataStatus.DONE && hasNext ?
                        (
                            <div className="load-more-issues-button">
                                <Button style={new Style(Theme.PRIMARY, Appearance.REGULAR)} args={{
                                    onClick: loadMore,
                                    disabled: disabled || !hasNext
                                }}>
                                    Meer laden...
                                </Button>
                            </div>
                        ) : null
                }

                {/* Show Errors */}
                <PageException dataStatus={dataStatus}
                               query={async () => {
                                   await query(DataStatus.PENDING);
                               }}
                               header={<h3>Problem issues kunnen niet geladen worden.</h3>}
                               itemName={"Problem issues"}
                               isPlural={true}
                               exception={exception}/>
            </div>
        </>
    );
}

export default ProblemIssues
