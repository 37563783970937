// Inspired by: https://www.learnbestcoding.com/post/71/persisting-state-after-page-refresh-react
import {useEffect, useState} from "react";

export function useLocalStoredState(name, initialState, clean = false, useSessionStorage = true) {
    const [data, setDataState] = useState(clean ? initialState : getLocalStorage() || initialState);

    useEffect(() => {
        setLocalStorage(data)
    }, [data]);

    function setLocalStorage(newData) {
        if (!useSessionStorage) {
            return;
        }

        localStorage.setItem(name, JSON.stringify(newData));
    }

    function getLocalStorage() {
        if (!useSessionStorage) {
            return null;
        }

        try {
            const localData = localStorage.getItem(name);
            if (localData != null) {
                return JSON.parse(localData);
            }
        } catch (e) {
            // Do nothing
        }

        return initialState;
    }

    function clear() {
        setData(initialState);
    }

    function getData() {
        return data;
    }

    function setData(newData) {
        setDataState(newData);
    }

    return [getData(), setData, clear, setLocalStorage];
}
