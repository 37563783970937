import Card from "../../Card/Card";
import React, {useState} from "react";
import Filter from "../../Filter/Filter";
import {DescriptionType, DescriptionTypeLabel} from "../../../shared/enums/description-type.enum";
import "./ProblemIssueDescription.css";

function ProblemIssueDescription(
    {
        issue
    }
) {
    const [descriptionType, setDescriptionType] = useState(issue.descriptionTypes[0]);

    if (issue.descriptionTypes.length === 0) {
        return null;
    }

    return (
        <>
            {
                issue.descriptionTypes.length > 1 ? (
                    <Filter selectedFilter={[descriptionType, setDescriptionType]}
                            filters={issue.descriptionTypes.map((key) => {
                                return {
                                    value: key, label: DescriptionTypeLabel(key)
                                }
                            })}/>
                ) : null
            }

            <Card className="issue-description">
                {
                    issue.descriptionTypes.length === 1 ? (
                        <header>
                            {DescriptionTypeLabel(descriptionType).toLowerCase()}
                        </header>
                    ) : null
                }

                <main>
                    {/*Show correct description*/}
                    {descriptionType === DescriptionType.REGULAR ? issue.description : null}
                    {descriptionType === DescriptionType.SOLUTION ? issue.solution : null}
                    {descriptionType === DescriptionType.SITE_COMMUNICATION ? issue.siteCommunication : null}
                </main>
            </Card>
        </>
    );
}

export default ProblemIssueDescription;
