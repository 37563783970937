import React, {useEffect, useState} from 'react';
import ProblemIssueSearchParams
    from "../../../../shared/data/models/problem-issue/params/search/problem-issue-search-params.model";
import {ProblemIssueSearchType} from "../../../../shared/enums/problem-issue-search-type.enum";
import ProblemIssueBasicSearchParams
    from "../../../../shared/data/models/problem-issue/params/search/problem-issue-basic-search-params.model";
import Input from "../../../Form/Input/Input";
import Form from "../../../Form/Form";
import {IconType} from "../../../../shared/enums/icon-type.enum";

function BasicSearch(
    {
        disabled = false,
        searchParams: [searchParams, setSearchParams],
        searchType: [searchType, setSearchType],
        setAutoRedirect
    }
) {
    const [query, setQuery] = useState(searchParams.params.query || "");

    useEffect(() => {
        setQuery(searchParams.params.query || "");
    }, [searchParams]);

    function basicSearch() {
        if (query) {
            const basicSearchParams = new ProblemIssueSearchParams(
                ProblemIssueSearchType.BASIC,
                new ProblemIssueBasicSearchParams(query)
            );

            setAutoRedirect(true);
            setSearchParams(basicSearchParams);
        } else {
            setSearchParams(new ProblemIssueSearchParams(ProblemIssueSearchType.NONE));
        }
    }

    // If the search type is not basic, don't render this component
    if (searchType !== ProblemIssueSearchType.BASIC) {
        return null;
    }

    return (
        <div>
            <Form handleSubmit={basicSearch} disabled={disabled} className={"form-inline"} buttonText="Zoeken"
                  icon={IconType.MAGNIFYING_GLASS} inputs={
                [
                    <Input key="basic-search"
                           nameAttribute="basic-search"
                           placeHolder="Zoeken op titel, interne id of externe id"
                           className="no-margin"
                           state={[query, setQuery]}/>
                ]
            }/>
        </div>
    )
}

export default BasicSearch
