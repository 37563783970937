import {useState} from "react";
import Button from "../Button/Button";
import {IconType} from "../../shared/enums/icon-type.enum";
import Style from "../../shared/data/models/style.model";
import {Theme} from "../../shared/enums/theme.enum";
import {Appearance} from "../../shared/enums/appearance.enum";
import "./Panel.css";

function Panel(
    {
        collapsable = false,
        initialCollapsed = false,
        disabled = false,
        title,
        children
    }
) {
    const [collapsed, setCollapsed] = useState(collapsable ? initialCollapsed : false);

    function toggleCollapse() {
        if (collapsable && !disabled) {
            setCollapsed(!collapsed);
        }
    }

    return (
        <article
            className={"collapse-panel " + (collapsable ? "collapse-panel-collapsable " : "") + (collapsed ? "collapse-panel-collapsed" : "collapse-panel-opened")}>
            <header onClick={toggleCollapse}>
                <div className="collapse-panel-title">
                    {title}
                </div>
                {
                    collapsable ? (
                        <div className="collapse-panel-toggle">
                            <Button icon={IconType.CHEVRON_DOWN}
                                    style={new Style(Theme.DARK, Appearance.MINIMAL)}
                                    args={{
                                        onClick: toggleCollapse,
                                        disabled: disabled
                                    }}/>
                        </div>
                    ) : null
                }
            </header>
            <hr/>
            <main>
                {children}
            </main>
            <hr className="collapse-panel-bottom-separator"/>


        </article>
    )
}

export default Panel;
