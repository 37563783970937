import React, {useEffect, useState} from 'react';

function TextAreaInput(
    {
        className,
        nameAttribute,
        idAttribute,
        placeHolder,
        disabled,
        state: [stateValue, stateSetter],
        required = false,
        maxlength = null,
        initialRows = 2,
        maxRows = 5
    }
) {
    const [rows, setRows] = useState(initialRows);

    useEffect(() => {
        const stateRows = stateValue.split("\n").length;

        // Set rows to stateRows if stateRows is greater than initialRows and less than or equal to MAX_ROWS
        if (stateRows > initialRows && stateRows <= maxRows) {
            setRows(stateRows);
        }
        // Set rows to MAX_ROWS if stateRows is greater than initialRows and greater than MAX_ROWS
        else if (stateRows > initialRows && stateRows > maxRows) {
            setRows(maxRows);
        }
        // Set rows to initialRows if stateRows is less than initialRows
        else {
            setRows(initialRows);
        }
    }, [stateValue, initialRows, maxRows]);

    return (
        <textarea
            className={className}
            name={nameAttribute}
            value={stateValue}
            placeholder={placeHolder}
            disabled={disabled}
            onChange={(e) => stateSetter(e.target.value)}
            id={idAttribute}
            required={required}
            maxLength={maxlength}
            rows={rows}/>
    )
}

export default TextAreaInput
