import React, {createContext, useContext, useState} from 'react';
import {UserService} from "../services/http-services/user.service";
import {DataStatus} from "../enums/data-status.enum";
import {AuthContext} from "./AuthContext";
import {CompanyService} from "../services/http-services/company.service";
import ApiValue from "../data/models/api-value.model.js";
import {StoreService} from "../services/http-services/store.service";
import {EquipmentService} from "../services/http-services/equipment.service";
import {ApplicationService} from "../services/http-services/application.service";
import {PriorityService} from "../services/http-services/priority.service";
import ErrorsPopup from "../../components/Errors/ErrorsPopup/ErrorsPopup";

export const ValuesContext = createContext({});

function ValuesContextProvider({children}) {
    const authContext = useContext(AuthContext);

    const userService = new UserService(authContext);
    const companyService = new CompanyService(authContext);
    const storeService = new StoreService(authContext);
    const equipmentService = new EquipmentService(authContext);
    const applicationService = new ApplicationService(authContext);
    const priorityService = new PriorityService(authContext);

    const [errorsHeader, setErrorsHeader] = useState(<h3>Kan gegevens niet ophalen.</h3>);
    const [errors, setErrors] = useState([]);

    const users = useState([]);
    const [userStatus, setUserStatus] = useState(DataStatus.INITIAL);
    const userPreviousArgs = useState(null);

    const companies = useState([]);
    const companyStatus = useState(DataStatus.INITIAL);
    const companyPreviousArgs = useState(null);

    const stores = useState([]);
    const storeStatus = useState(DataStatus.INITIAL);
    const storePreviousArgs = useState(null);

    const equipments = useState([]);
    const equipmentStatus = useState(DataStatus.INITIAL);
    const equipmentPreviousArgs = useState(null);

    const applications = useState([]);
    const applicationStatus = useState(DataStatus.INITIAL);
    const applicationPreviousArgs = useState(null);

    const priorities = useState([]);
    const priorityStatus = useState(DataStatus.INITIAL);
    const priorityPreviousArgs = useState(null);

    const contextData = {
        userValues: new ApiValue(users, [userStatus, setUserStatus], userPreviousArgs, async () => await userService.usersReduced(), [setErrors, setErrorsHeader, "Kan gebruikers niet ophalen."]),
        companyValues: new ApiValue(companies, companyStatus, companyPreviousArgs, async () => await companyService.companies(), [setErrors, setErrorsHeader, "Kan bedrijven niet ophalen."]),
        storeValues: new ApiValue(stores, storeStatus, storePreviousArgs, async (company) => await storeService.stores(company), [setErrors, setErrorsHeader, "Kan winkels niet ophalen."]),
        equipmentValues: new ApiValue(equipments, equipmentStatus, equipmentPreviousArgs, async (company) => await equipmentService.equipments(company), [setErrors, setErrorsHeader, "Kan onderdelen niet ophalen."]),
        applicationValues: new ApiValue(applications, applicationStatus, applicationPreviousArgs, async (company, equipment) => await applicationService.applications(company, equipment), [setErrors, setErrorsHeader, "Kan applicaties niet ophalen."]),
        priorityValues: new ApiValue(priorities, priorityStatus, priorityPreviousArgs, async (company) => await priorityService.priorities(company), [setErrors, setErrorsHeader, "Kan prioriteiten niet ophalen."]),
    };

    return (
        <ValuesContext.Provider value={contextData}>
            <>
                {children}

                <ErrorsPopup title="Ophalen gegevens mislukt."
                             header={errorsHeader}
                             errors={[errors, setErrors]}/>
            </>
        </ValuesContext.Provider>
    )
}

export default ValuesContextProvider;
