import UnauthenticatedLayout from "../../../components/Layout/UnauthenticatedLayout/UnauthenticatedLayout";
import {Link} from "react-router-dom";
import React from "react";

function RegisterSuccess() {
    return (
        <UnauthenticatedLayout title="Successvol geregistreerd!">
            <p>
                Uw account is successvol aangemaakt. Een administrator zal uw account zo snel mogelijk goedkeuren.
            </p>
            <p>
                <Link to="/login">Terug naar inloggen</Link>
            </p>
        </UnauthenticatedLayout>
    );
}

export default RegisterSuccess;
