import {HttpService} from "./http.service";
import {HttpMethod} from "../../enums/http-method.enum";
import DescriptorSerializer from "../../data/serializers/descriptor.serializer";

export class CompanyService extends HttpService {

    constructor(authContext) {
        super(authContext, "Companies", new DescriptorSerializer());
    }

    async companies() {
        const response = await this.request("", HttpMethod.GET);

        if (response) {
            try {
                return this.serializer.fromJsonArray(response.data);
            } catch (e) {
                // Do nothing
            }
        }

        return [];
    }
}
