function deleteStateFromHistory(key) {
    if (window.history.state && window.history.state.usr && window.history.state.usr[key]) {
        let newState = window.history.state;
        delete newState.usr[key];

        window.history.replaceState(newState, "");
    }
}

function clearHistory() {
    if (window.history.state && window.history.state.usr) {
        let newState = window.history.state;

        let from = window.history.state.usr.from;
        if (from) {
            newState.usr = {from: from};
        } else {
            newState.usr = null;
        }

        window.history.replaceState(newState, "");
    }
}

export {deleteStateFromHistory, clearHistory};
