import React, {useState} from 'react';
import RegularButton from "../RegularButton/RegularButton";

function DisablingButton(
    {
        title,
        type,
        onClick,
        className = "",
        children
    }
) {
    const [disabled, setDisabled] = useState(false);

    function onClickInternal() {
        setDisabled(true);

        if (onClick) {
            onClick();
        }
    }

    return (
        <RegularButton title={title} type={type} onClick={onClickInternal} disabled={disabled} className={className}>
            {children}
        </RegularButton>
    )
}

export default DisablingButton
