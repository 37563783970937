import {Link} from "react-router-dom";
import React from "react";
import "./User.css";

function User(
    {
        user,
        showLink = true,
        newTab = false,
    }
) {
    if (!user) {
        return null;
    }

    return (
        <span className="user-name">
            {
                user.id && showLink ?
                    <Link to={`/profile/${user.id}`} target={newTab ? "_target" : null}>
                        {user.fullName}
                    </Link>
                    : user.fullName
            }
        </span>
    );
}

export default User;
