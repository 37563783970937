import React from 'react';
import Layout from "../Layout/Layout";
import "./UnauthenticatedLayout.css";

function UnauthenticatedLayout(
    {
        title,
        children
    }
) {
    return (
        <Layout>
            <main id="layout-container">
                <section className="layout-image"></section>
                <section className="layout-content">
                    <div className="layout-content-container">
                        <header>
                            <img src="/images/AXI_logo.png" alt="Logo"/>
                            <h1>
                                {title}
                            </h1>
                        </header>
                        <main>
                            {children}
                        </main>
                    </div>
                </section>
            </main>
        </Layout>
    )
}

export default UnauthenticatedLayout
