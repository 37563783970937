import {ExceptionType} from "../enums/exception-type.enum";
import {Exception} from "./exception";

export class BadRequestException extends Exception {
    errors;

    constructor(errors = []) {
        super(`Bad Request!`, ExceptionType.BAD_REQUEST);

        this.errors = errors;

        if (errors && errors.length === 0) {
            this.errors.push("Er is een onbekende fout opgetreden.");
        }
    }
}
