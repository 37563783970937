import {useState, useEffect, useRef} from 'react';
import {DataStatus} from "../../enums/data-status.enum";

export function useData(getData, initialState = null, deps = [], skipLoad = false) {
    let ignore = useRef(false);

    const [data, setData] = useState(initialState);
    const [status, setStatus] = useState(DataStatus.INITIAL);
    const [exception, setException] = useState([]);

    useEffect(() => {
        ignore.current = false;

        void load();
        return () => {
            ignore.current = true;
        }
    }, deps);

    async function load(pendingStatus = DataStatus.PENDING) {
        if (skipLoad) {
            setStatus(DataStatus.DONE);
            return;
        }

        if (getData) {
            setStatus(pendingStatus);
            clear();
            try {
                const data = await getData();

                if (!ignore.current) {
                    setData(data);
                    setStatus(DataStatus.DONE);
                }
            } catch (e) {
                setException(e);
                setStatus(DataStatus.ERROR);
            }
        }
    }

    function clear() {
        setData(initialState);
    }

    return [data, status, load, clear, exception];
}
