import {useEffect, useRef, useState} from "react";

export function useTimer(interval = 1000) {
    const [newDuration, setNewDuration] = useState(0);
    const [originalDuration, setOriginalDuration] = useState(0);
    const [duration, setDuration] = useState(0);
    const [isRunning, setIsRunning] = useState(false);

    const timer = useRef(null);

    useEffect(() => {
        // If the timer is running and a new duration is set, clear the timer
        // The original duration will be set on clear
        if(isRunning && newDuration > 0) {
            clear();
            return;
        }

        // If the timer is not running and the original duration is set, set the duration
        // This will kick off the timer
        if(originalDuration > 0) {
            setDuration(originalDuration);
            return;
        }

        // If the timer is not running and a new duration is set, set the original duration
        if(newDuration > 0) {
            setOriginalDuration(newDuration);
            setNewDuration(0);
        }
    }, [newDuration, originalDuration, isRunning]);

    // Timer loop
    useEffect(() => {
        if(duration <= 0) {
            clear();
            return;
        }

        setIsRunning(true);

        let newInterval = interval;
        if(duration < interval) {
            newInterval = duration;
        }

        timer.current = setTimeout(() => {
            setDuration(duration - newInterval);
        }, newInterval);
    }, [duration, interval]);

    function set(newDuration) {
        setNewDuration(newDuration);
    }

    function stop() {
        if (timer.current) {
            clearInterval(timer.current);
            timer.current = null;
        }

        setIsRunning(false);
    }

    function clear() {
        stop();
        setOriginalDuration(0);
    }

    return [duration, originalDuration, isRunning, set, clear]
}
