import React from 'react';
import {useParams} from "react-router-dom";
import AuthenticatedLayout from "../../../components/Layout/AuthenticatedLayout/AuthenticatedLayout";
import {CrudOperation} from "../../../shared/enums/crud-operation.enum";
import ProblemIssueForm from "../../../components/Form/ProblemIssue/ProblemIssueForm";

function ProblemIssueEdit() {
    const {id} = useParams();

    return (
        <AuthenticatedLayout backTo={"/issue/details/" + id} title={id + " wijzigen"}>
            <ProblemIssueForm crudOperation={CrudOperation.UPDATE}
                              issueId={id}/>
        </AuthenticatedLayout>
    );
}

export default ProblemIssueEdit
