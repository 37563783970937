import {ValuesContext} from "../../../../shared/context/ValuesContext";
import {useContext, useEffect} from "react";
import {DataStatus} from "../../../../shared/enums/data-status.enum";
import Input from "../Input";
import {AuthContext} from "../../../../shared/context/AuthContext";

function UserInput(
    {
        nameAttribute,
        disabled,
        state,
        labelText,
        placeHolder,
        required = false,
        maxlength = null,
        pattern = null,
        patternMessage = null,
    }
) {
    const {user} = useContext(AuthContext);
    const {userValues} = useContext(ValuesContext);

    useEffect(() => {
        execute();
    }, []);

    async function execute() {
        await userValues.execute([]);
    }

    return (
        <Input
            nameAttribute={nameAttribute}
            inputType="datalist"
            disabled={disabled || userValues.status !== DataStatus.DONE || userValues.state.length === 0}
            loading={userValues.status === DataStatus.PENDING}
            state={state}
            options={
                [
                    // Add the logged-in user as first option
                    ...userValues.state.filter(descriptor => descriptor.id === user.id).map(descriptor => {
                        return {value: descriptor.id, label: descriptor.fullName}
                    }),
                    // Add the rest of the users as options, don't add the logged-in user again and don't add users without id
                    ...userValues.state.filter(descriptor => descriptor.id !== user.id && !!descriptor.id).map(descriptor => {
                        return {value: descriptor.id, label: descriptor.fullName}
                    })
                ]
            }
            labelText={labelText}
            placeHolder={placeHolder}
            required={required}
            maxlength={maxlength}
            pattern={pattern}
            patternMessage={patternMessage}
            className={userValues.status === DataStatus.ERROR ? "has-error" : ""}
            onClick={userValues.status === DataStatus.ERROR ? async () => {
                await execute();
            } : null}
            buttonTitle="Opnieuw proberen"/>
    );
}

export default UserInput;
