import React, {useContext, useState} from "react";
import "./Activity.css";
import {AuthContext} from "../../../shared/context/AuthContext";
import Card from "../../Card/Card";
import User from "../../User/User";
import {ActivityType} from "../../../shared/enums/activity-type.enum";
import {DescriptionTypeLabel} from "../../../shared/enums/description-type.enum";
import Icon from "../../Icon/Icon";
import {IconType} from "../../../shared/enums/icon-type.enum";
import {ProblemIssueService} from "../../../shared/services/http-services/problem-issue.service";
import ConfirmPopup from "../../Popup/ConfirmPopup/ConfirmPopup";
import ErrorsPopup from "../../Errors/ErrorsPopup/ErrorsPopup";
import parseException from "../../../shared/util/parse-exception";
import {SuccessContext} from "../../../shared/context/SuccessContext";

function Activity(
    {
        activity,
        issueId,
        reload
    }
) {
    const {showSuccess} = useContext(SuccessContext);
    const authContext = useContext(AuthContext);
    const problemIssueService = new ProblemIssueService(authContext);

    const [errors, setErrors] = useState([]);
    const [confirmDelete, setConfirmDelete] = useState(false);

    async function deleteComment() {
        try {
            const response = await problemIssueService.deleteComment(issueId, activity);

            if (response) {
                reload();
                showSuccess("Opmerking is verwijderd!", 2000);
            }
        } catch (e) {
            parseException(e, setErrors);
        }
    }

    return (
        <Card
            className={
                "activity " +
                (authContext.user?.id === activity.user.id ? "own-activity " : "") +
                (activity.type === ActivityType.UPDATE ? "activity-update" : "") +
                (activity.type === ActivityType.COMMENT && authContext.user?.id === activity.user.id ? "has-trash" : "")}>
            <header>
                <div className="activity-info">
                    {activity.dateTime.readableDate()} -&nbsp;
                    <User user={activity.user}/>
                </div>
                {
                    activity.type === ActivityType.COMMENT && activity.origin ? (
                        <div className="activity-origin">
                            {DescriptionTypeLabel(activity.origin).toLowerCase()}
                        </div>
                    ) : null
                }
            </header>

            <main>
                <p className="activity-content">
                    {activity.message}
                </p>
            </main>

            {
                activity.type === ActivityType.COMMENT && authContext.user?.id === activity.user.id ? (
                    <>
                        <footer onClick={() => {
                            setConfirmDelete(true)
                        }}>
                            <Icon
                                icon={IconType.TRASH}/>
                        </footer>

                        <ConfirmPopup
                            visible={[confirmDelete, setConfirmDelete]}
                            onConfirm={deleteComment}
                            message="Weet je zeker dat je deze opmerking wilt verwijderen?"/>

                        <ErrorsPopup header="Kan opmerking niet verwijderen."
                                     errors={[errors, setErrors]}/>
                    </>
                ) : null
            }
        </Card>
    )
}

export default Activity
