import IdentifierSerializer from "../identifier.serializer";
import UserReduced from "../../models/user/user-reduced.model";

export default class UserReducedSerializer extends IdentifierSerializer {
    fromJson(json) {
        const user = new UserReduced(super.fromJson(json));
        user.fullName = json.fullName;

        return user;
    }

    fromJsonArray(jsonArray) {
        try {
            if (jsonArray.length > 0) {
                return jsonArray.map(json => this.fromJson(json));
            }
        } catch (e) {
            // Do nothing
        }

        return [];
    }

    toJson(user) {
        let json = super.toJson(user);
        json.fullName = user.fullName;

        return json;
    }

    toJsonArray(users) {
        try {
            if (users.length > 0) {
                return users.map(user => this.toJson(user));
            }
        } catch (e) {
            // Do nothing
        }

        return [];
    }
}
