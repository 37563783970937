import {Link, NavLink} from "react-router-dom";

function MenuItem
(
    {
        to,
        state,
        name,
        onClick
    }
) {
    return (
        <>
            {
                to ? (
                    <li>
                        <NavLink className={({isActive}) => isActive ? 'active' : ''}
                                 to={to}
                                 state={state}
                                 onClick={onClick}>
                            {name}
                        </NavLink>
                    </li>
                ) : (
                    <Link to="#"
                          onClick={onClick}>
                        {name}
                    </Link>
                )
            }
        </>
    );
}

export default MenuItem;
