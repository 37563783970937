import React from 'react';

function SelectInput(
    {
        className,
        nameAttribute,
        idAttribute,
        disabled,
        state: [stateValue, stateSetter],
        options,
        required = false
    }
) {
    return (
        <select
            className={className}
            name={nameAttribute}
            value={stateValue}
            disabled={disabled}
            onChange={(e) => stateSetter(e.target.value)}
            id={idAttribute}
            required={required}>
            {
                options.map(
                    ({value, label}) =>
                        <option
                            key={value}
                            value={value}>
                            {label}
                        </option>
                )
            }
        </select>
    )
}

export default SelectInput
