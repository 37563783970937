import {useEffect, useState} from "react";
import throttle from "../../../shared/util/throttle";
import "./ScrollButton.css";
import Icon from "../../Icon/Icon";
import {IconType} from "../../../shared/enums/icon-type.enum";

function ScrollButton() {
    const [visible, setVisible] = useState(false);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    const onScroll = async () => {
        const scrollTop = document.documentElement.scrollTop;
        const clientHeight = document.documentElement.clientHeight;

        if (scrollTop > clientHeight / 2) {
            setVisible(true);
        } else {
            setVisible(false);
        }
    }

    useEffect(() => {
        const throttleFunction = throttle(onScroll, 10);
        window.addEventListener('scroll', throttleFunction);


        return () => {
            window.removeEventListener('scroll', throttleFunction);
        }
    }, []);

    return (
        <div className={"scroll-button " + (visible ? "" : "hidden")} onClick={scrollToTop}>
            <Icon icon={IconType.CHEVRON_UP}/>
        </div>
    );
}

export default ScrollButton;
