import {getIcon} from "../../shared/enums/icon-type.enum";
import "./Icon.css";

function Icon({icon}) {
    if (!icon) {
        return null;
    }

    const iconSvg = getIcon(icon);
    return (
        <span className="icon">{iconSvg}</span>
    );
}

export default Icon;
