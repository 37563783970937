import React, {useEffect} from "react";
import "./Success.css";
import Icon from "../Icon/Icon";
import {IconType} from "../../shared/enums/icon-type.enum";
import CountDown from "../CountDown/CountDown";
import {useTimer} from "../../shared/util/custom-hooks/use-timer";

function Success(
    {
        successData: [successData, setSuccessData] = [null, (data) => {
        }],
    }
) {
    const [, originalDuration, isRunning, setTimer, clearTimer] = useTimer(1000);

    useEffect(() => {
        if (successData) {
            setTimer(successData.duration || 5000);
        }
    }, [successData]);

    useEffect(() => {
        return () => {
            // Clear timer on unmount
            clearTimer();
        };
    }, []);

    function clear() {
        clearTimer();
        setSuccessData(null);
    }

    if (!successData || !isRunning) {
        return null;
    }

    return (
        <div className="success-message">
            <div className="success-message-icon">
                <CountDown duration={originalDuration} isRunning={isRunning}>
                    <Icon icon={IconType.CHECK}/>
                </CountDown>
            </div>
            <div className="success-message-content">
                <h2>{successData.title}</h2>
                {
                    successData.description ?
                        (
                            <p>{successData.description}</p>
                        ) : null
                }
            </div>
            <div className="success-message-close" onClick={clear}>
                <Icon icon={IconType.XMARK}/>
            </div>
        </div>
    );
}

export default Success;
