import UserReduced from "./user-reduced.model";

export default class User extends UserReduced {
    firstName;
    lastName;
    email;

    needsCompletion() {
        return !this.firstName || !this.lastName || !this.fullName || !this.email;
    }
}
