import {DataStatus} from "../../../shared/enums/data-status.enum";
import Errors from "../Errors";
import Button from "../../Button/Button";
import Style from "../../../shared/data/models/style.model";
import {Theme} from "../../../shared/enums/theme.enum";
import {Appearance} from "../../../shared/enums/appearance.enum";
import {IconType} from "../../../shared/enums/icon-type.enum";
import {useState, useEffect} from "react";
import parseException from "../../../shared/util/parse-exception";
import {useNavigate} from 'react-router-dom';
import {ExceptionType} from "../../../shared/enums/exception-type.enum";
import {Position} from "../../../shared/enums/position.enum";

function PageException(
    {
        dataStatus,
        query,
        exception = null,
        itemName = "Pagina",
        isPlural = false
    }
) {
    const navigate = useNavigate();

    const [errors, setErrors] = useState([]);
    useEffect(() => {
        parseException(exception, setErrors, itemName);
    }, [exception, itemName]);

    if (dataStatus !== DataStatus.ERROR || exception === null) {
        return null;
    }

    return (
        <div className="problem-issues-errors">
            <Errors
                header={
                    isPlural ?
                        (
                            <h2>{itemName} kunnen niet geladen worden.</h2>
                        ) : (
                            <h2>{itemName} kan niet geladen worden.</h2>
                        )
                }
                errors={errors}/>

            {
                exception.name === ExceptionType.NOT_FOUND ?
                    // Go back and home button
                    (
                        <div className="btn-container">
                            <Button style={new Style(Theme.PRIMARY, Appearance.OUTLINE)}
                                    icon={IconType.ARROW_LEFT}
                                    iconPosition={Position.LEFT}
                                    args={{
                                        onClick: () => {
                                            navigate(-1);
                                        },
                                    }}>
                                Ga terug
                            </Button>
                            <Button style={new Style(Theme.PRIMARY, Appearance.REGULAR)}
                                    icon={IconType.HOME}
                                    args={{
                                        onClick: () => {
                                            navigate("/");
                                        },
                                    }}>
                                Naar overzicht
                            </Button>
                        </div>
                    ) : (
                        // Retry button
                        <Button style={new Style(Theme.PRIMARY, Appearance.REGULAR)}
                                icon={IconType.ARROW_ROTATE_RIGHT}
                                args={{
                                    onClick: query,
                                }}>
                            Opnieuw proberen
                        </Button>
                    )
            }
        </div>
    );
}

export default PageException;
