import {QuickFilterType, QuickFilterTypeLabel} from "../../../../shared/enums/quick-filter-type.enum";
import "./QuickFilterItem.css";

function QuickFilterItem
(
    {
        type = QuickFilterType.ALL,
        selected = false,
        setSelectedType,
        disabled = false,
    }
) {
    return (
        <div className={"quick-filter " + (selected ? "active " : "") + (disabled ? "disabled" : "")}
             onClick={disabled ? () => {
                 // disabled
             } : () => {
                 setSelectedType(type)
             }}>
            {QuickFilterTypeLabel(type)}
        </div>
    );
}

export default QuickFilterItem;
