import {CrudOperation} from "../../../shared/enums/crud-operation.enum";
import Input from "../Input/Input";
import {ProblemIssueStatus} from "../../../shared/enums/problem-issue-status.enum";
import Status from "../../../shared/data/models/status.model";
import ProblemIssueApiValues from "../../ProblemIssue/ApiValues/ProblemIssueApiValues";
import {DescriptionType, DescriptionTypeLabel} from "../../../shared/enums/description-type.enum";
import React, {useContext, useEffect, useState} from "react";
import Form from "../Form";
import Card from "../../Card/Card";
import "./ProblemIssueForm.css";
import Filter from "../../Filter/Filter";
import {ProblemIssueService} from "../../../shared/services/http-services/problem-issue.service";
import {useData} from "../../../shared/util/custom-hooks/use-data";
import {AuthContext} from "../../../shared/context/AuthContext";
import {DataStatus} from "../../../shared/enums/data-status.enum";
import {useNavigate} from "react-router-dom";
import ProblemIssueParams from "../../../shared/data/models/problem-issue/params/problem-issue-params";
import Loading from "../../../pages/Loading/Loading";
import PageException from "../../Errors/PageException/PageException";
import {SuccessContext} from "../../../shared/context/SuccessContext";

function ProblemIssueForm
(
    {
        crudOperation = null,
        issueId = null,
    }
) {
    const {showSuccess} = useContext(SuccessContext);
    const authContext = useContext(AuthContext);
    const issueService = new ProblemIssueService(authContext);
    const navigate = useNavigate();

    const [issue, dataStatus, reload, , exception] = useData(() => issueService.getIssue(issueId), {}, [], crudOperation !== CrudOperation.UPDATE);
    const [descriptionType, setDescriptionType] = useState(DescriptionType.REGULAR);

    const [skipNextReset, setSkipNextReset] = useState(crudOperation === CrudOperation.UPDATE);
    const [submitting, setSubmitting] = useState(false);
    const [disabled, setDisabled] = useState(submitting);
    useEffect(() => {
        setDisabled(submitting || (crudOperation === CrudOperation.UPDATE && dataStatus !== DataStatus.DONE));
    }, [submitting, crudOperation, dataStatus]);

    const [title, setTitle] = useState("");
    const [id, setId] = useState("");
    const [externalId, setExternalId] = useState("");
    const [status, setStatus] = useState(crudOperation === CrudOperation.CREATE ? ProblemIssueStatus.TODO : "");
    const [resolveTime, setResolveTime] = useState("");

    const [company, setCompany] = useState("");
    const [store, setStore] = useState(issue.store || "");
    const [equipment, setEquipment] = useState("");
    const [application, setApplication] = useState("");
    const [priority, setPriority] = useState("");

    const [description, setDescription] = useState("");
    const [solution, setSolution] = useState("");
    const [siteCommunication, setSiteCommunication] = useState("");

    const [allocatedTo, setAllocatedTo] = useState("");
    const [billed, setBilled] = useState(crudOperation === CrudOperation.CREATE ? "false" : "");

    useEffect(() => {
        if (dataStatus !== DataStatus.DONE || !issue) {
            return;
        }

        setTitle(issue.title || "");
        setId(issue.id || "");
        setExternalId(issue.externalId || "");
        setStatus(issue.status?.problemIssueStatus || ProblemIssueStatus.TODO);
        setResolveTime(issue.resolveTime || "");

        setCompany(sanitizeId(issue.company?.id));
        setStore(sanitizeId(issue.store?.id));
        setEquipment(sanitizeId(issue.equipment?.id));
        setApplication(sanitizeId(issue.application?.id));
        setPriority(sanitizeId(issue.priority?.id));

        setDescription(issue.description || "");
        setSolution(issue.solution || "");
        setSiteCommunication(issue.siteCommunication || "");

        setAllocatedTo(issue.allocatedTo?.id || "");
        setBilled(issue.billed || "false");

        setSkipNextReset(false);
    }, [issue]);

    // Crud operation is required and must be create or update
    if (!crudOperation && crudOperation !== CrudOperation.CREATE && crudOperation !== CrudOperation.UPDATE) {
        return null;
    }

    // If crud operation is update, issue id is required
    if (crudOperation === CrudOperation.UPDATE && !issueId) {
        return null;
    }

    function sanitizeId(id) {
        return id || id === 0 ? id : "";
    }

    async function handle() {
        let issue = createIssue();

        let redirectToId = false;
        if (crudOperation === CrudOperation.CREATE) {
            const response = await issueService.createIssue(issue);

            if (response && response.id) {
                redirectToId = response.id;
                showSuccess("Problem issue is aangemaakt!", 3000);
            }
        } else if (crudOperation === CrudOperation.UPDATE) {
            const response = await issueService.updateIssue(issueId, issue);

            if (response) {
                redirectToId = issueId;
                showSuccess("Problem issue is gewijzigd!", 3000);
            }
        }

        if (redirectToId) {
            navigate(`/issue/details/${redirectToId}`);
        }
    }

    function createIssue() {
        return new ProblemIssueParams(
            issueId, externalId, title, status, resolveTime,
            company, store, equipment, application, priority,
            description, solution, siteCommunication,
            allocatedTo, billed
        );
    }

    if (crudOperation === CrudOperation.UPDATE) {
        if (dataStatus !== DataStatus.DONE && dataStatus !== DataStatus.ERROR) {
            return <Loading/>;
        }

        if (dataStatus === DataStatus.ERROR) {
            return (
                <PageException dataStatus={dataStatus}
                               query={async () => {
                                   await reload();
                               }}
                               itemName={"Problem issue"}
                               exception={exception}/>
            );
        }
    }

    return (
        <Form useLoader={true}
              handleSubmit={handle}
              setExternalSubmitting={setSubmitting}
              buttonText={crudOperation === CrudOperation.CREATE ? "Aanmaken" : "Wijzigen"}>
            <div className={"problem-issue-form-container"}>
                <Card className="problem-issue-form-ids">
                    {
                        crudOperation === CrudOperation.UPDATE ? (
                            <Input key="advanced-search-id"
                                   nameAttribute="advanced-search-id"
                                   labelText="Interne id"
                                   state={[id, setId]}
                                   disabled={true}/>
                        ) : null
                    }
                    <Input key="advanced-search-external-id"
                           nameAttribute="advanced-search-external-id"
                           labelText="Externe id"
                           state={[externalId, setExternalId]}
                           disabled={disabled}
                           maxlength={30}/>
                </Card>

                <Card className="problem-issue-form-general">
                    <Input key="advanced-search-status"
                           nameAttribute="advanced-search-status"
                           inputType="select"
                           labelText="Status"
                           state={[status, setStatus]}
                           disabled={disabled}
                           required={true}
                           options={
                               Object.keys(ProblemIssueStatus)
                                   .filter(aStatus => aStatus !== ProblemIssueStatus.UNKNOWN && aStatus !== ProblemIssueStatus.ALL)
                                   .map(key => {
                                       const status = new Status(key);
                                       return {
                                           value: status.problemIssueStatus,
                                           label: status.description
                                       }
                                   })
                           }/>
                    {
                        status === ProblemIssueStatus.RESOLVED || status === ProblemIssueStatus.CLOSED || status === ProblemIssueStatus.CANCELLED ?
                            (
                                <Input key="advanced-search-resolve-time"
                                       nameAttribute="advanced-search-resolve-time"
                                       inputType="number"
                                       labelText="Oplostijd"
                                       state={[resolveTime, setResolveTime]}
                                       disabled={disabled}
                                       step="0.25"
                                       maxDigits={14}
                                       maxDecimals={2}/>
                            ) : null
                    }
                    <Input key="advanced-search-allocated-to"
                           nameAttribute="advanced-search-allocated-to"
                           inputType="user"
                           labelText="Toegewezen aan"
                           state={[allocatedTo, setAllocatedTo]}
                           disabled={disabled}
                           maxlength={4}/>
                    <Input key="advanced-search-billed"
                           nameAttribute="advanced-search-billed"
                           inputType="select"
                           labelText="Gefactureerd"
                           state={[billed, setBilled]}
                           disabled={disabled}
                           required={true}
                           options={
                               [
                                   {
                                       value: "true", label: "Ja"
                                   },
                                   {
                                       value: "false", label: "Nee"
                                   },
                               ]
                           }/>
                </Card>

                <Card className="problem-issue-form-api-values">
                    <ProblemIssueApiValues key="advanced-search-values"
                                           company={[company, setCompany]}
                                           store={[store, setStore]}
                                           equipment={[equipment, setEquipment]}
                                           application={[application, setApplication]}
                                           priority={[priority, setPriority]}
                                           disabled={disabled}
                                           skipNextReset={skipNextReset}
                                           required={true}/>
                </Card>

                <Card className="problem-issue-form-title">
                    <Input key="advanced-search-title"
                           nameAttribute="advanced-search-title"
                           labelText="Titel"
                           state={[title, setTitle]}
                           disabled={disabled}
                           required={true}
                           maxlength={100}/>
                </Card>

                <Card className="problem-issue-form-descriptions">
                    <Filter selectedFilter={[descriptionType, setDescriptionType]}
                            filters={Object.keys(DescriptionType).map((key) => {
                                return {
                                    value: key, label: DescriptionTypeLabel(key)
                                }
                            })}/>

                    {
                        descriptionType === DescriptionType.REGULAR ? (
                            <Input key="advanced-search-description"
                                   nameAttribute="advanced-search-description"
                                   inputType="textarea"
                                   state={[description, setDescription]}
                                   disabled={disabled}
                                   initialRows={5}
                                   maxRows={25}/>
                        ) : null
                    }
                    {
                        descriptionType === DescriptionType.SOLUTION ? (
                            <Input key="advanced-search-solution"
                                   nameAttribute="advanced-search-solution"
                                   inputType="textarea"
                                   state={[solution, setSolution]}
                                   disabled={disabled}
                                   initialRows={5}
                                   maxRows={25}/>
                        ) : null
                    }
                    {
                        descriptionType === DescriptionType.SITE_COMMUNICATION ? (
                            <Input key="advanced-search-site-communication"
                                   nameAttribute="advanced-search-site-communication"
                                   inputType="textarea"
                                   state={[siteCommunication, setSiteCommunication]}
                                   disabled={disabled}
                                   initialRows={5}
                                   maxRows={25}/>
                        ) : null
                    }
                </Card>
            </div>
        </Form>
    );
}

export default ProblemIssueForm;
