export const Theme = {
    INFO: "info",
    SUCCESS: "success",
    WARNING: "warning",
    DANGER: "danger",
    PRIMARY: "primary",
    SECONDARY: "secondary",
    LIGHT: "light",
    DARK: "dark",
}


