import React, {useContext, useEffect, useRef, useState} from 'react';
import Navbar from "./Navbar/Navbar";
import Layout from "../Layout/Layout";
import "./AuthenticatedLayout.css";
import {AsideMobileStyle} from "../../../shared/enums/aside-mobile-style.enum";
import Popup from "../../Popup/Popup";
import {useMediaQuery} from "../../../shared/util/custom-hooks/use-media-query";
import {ButtonType} from "../../../shared/enums/button-type.enum";
import Button from "../../Button/Button";
import {IconType} from "../../../shared/enums/icon-type.enum";
import {Appearance} from "../../../shared/enums/appearance.enum";
import {Theme} from "../../../shared/enums/theme.enum";
import Style from "../../../shared/data/models/style.model";
import ScrollButton from "../../Button/ScrollButton/ScrollButton";
import {useLocation} from "react-router-dom";
import {AuthContext} from "../../../shared/context/AuthContext";

function AuthenticatedLayout(
    {
        title,
        backTo,
        pageButtons,
        pageButtonsVisible = !!pageButtons,
        children,
        contentHeader,
        aside,
        asideVisible = !!aside,
        asideSticky = false,
        asideHeader,
        closeAside,
        asideMobileStyle = AsideMobileStyle.REGULAR,
    }
) {
    const {validateToken, clearAuthentication} = useContext(AuthContext);
    const location = useLocation();
    const matches = useMediaQuery('(max-width: 991px)');

    const asideRef = useRef(null);
    const [asideStickied, setAsideStickied] = useState(false);

    // Check if token is still valid on each AuthenticatedLayout mount
    useEffect(() => {
        // If token is not valid, clear authentication
        if(!validateToken()) {
            clearAuthentication();
        }
    }, []);

    // Observe aside
    useEffect(() => {
        const observer = new IntersectionObserver(onObserverChange, {
            root: null,
            rootMargin: "0px",
            threshold: 1
        });
        if (asideRef.current) {
            observer.observe(asideRef.current);
        }

        return () => {
            if (asideRef.current) {
                observer.unobserve(asideRef.current);
            }
        }
    }, [asideRef, asideVisible]);

    function onObserverChange(entries) {
        const [entry] = entries;
        setAsideStickied(entry.isIntersecting);
    }

    if (location.state?.from) {
        backTo = location.state.from;
    }

    return (
        <Layout>
            <div id="default-layout">
                <Navbar/>
                <header id="default-layout-header">
                    <div className="default-layout-header-title">
                        {
                            backTo ?
                                (
                                    <Button btnType={ButtonType.LINK} style={new Style(Theme.DARK, Appearance.OUTLINE)}
                                            icon={IconType.ARROW_LEFT} args={{to: backTo}}/>
                                ) : null
                        }
                        <h1>{title}</h1>
                    </div>

                    {
                        pageButtonsVisible && pageButtons ? (
                            <div className="default-layout-header-buttons btn-container">
                                {pageButtons}
                            </div>
                        ) : null
                    }
                </header>
                <main id="default-layout-main"
                      className={(asideVisible ? "has-aside " : "") + (!contentHeader && !asideHeader ? "no-headers" : "")}>
                    {
                        contentHeader ? (
                            <header
                                className={"default-layout-content-header " + (asideVisible && asideHeader ? "has-aside-header" : "")}>
                                {contentHeader}
                            </header>
                        ) : null
                    }

                    <section className="default-layout-content">
                        {children}
                    </section>

                    {
                        asideVisible ?
                            (
                                <>
                                    {
                                        asideHeader ? (
                                            <header className="default-layout-aside-title">
                                                {asideHeader}
                                            </header>
                                        ) : null
                                    }

                                    <aside
                                        className={"default-layout-aside " + (asideSticky ? "aside-sticky " : "") + (asideStickied ? "aside-stickied " : "")}
                                        ref={asideRef}>
                                        {
                                            asideMobileStyle === AsideMobileStyle.POPUP && matches ?
                                                (
                                                    <Popup closePopup={closeAside} fullscreen={true}>
                                                        {aside}
                                                    </Popup>
                                                ) : (
                                                    <section className="default-layout-aside-content">
                                                        {aside}
                                                    </section>
                                                )
                                        }
                                    </aside>
                                </>
                            ) : null
                    }
                </main>
            </div>
            <ScrollButton/>
        </Layout>
    )
}

export default AuthenticatedLayout
