import React, {createContext, useEffect, useState} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import checkIfTokenIsValid from "../util/check-if-token-is-valid";
import {DataStatus} from "../enums/data-status.enum";
import Loading from "../../pages/Loading/Loading";
import {AccountService} from "../services/http-services/account.service";

export const AuthContext = createContext({});

function AuthContextProvider({children}) {
    // Constants
    const location = useLocation();
    const navigate = useNavigate();
    const accountService = new AccountService();

    // Init state
    const [isAuth, setIsAuth] = useState({
        isAuthenticated: false,
        user: null,
        roles: [],
        status: DataStatus.PENDING,
    });

    // On page reload
    useEffect(() => {
        // Get and process token
        const token = localStorage.getItem('token');
        void processToken(token);
    }, []);

    async function processToken(token) {
        if (token && validateToken(token)) {
            try {
                let account = await getAccount();
                setIsAuth({
                    isAuthenticated: true,
                    user: account.user,
                    roles: account.roles,
                    status: DataStatus.DONE,
                });

                return true;
            } catch (e) {
                // Do nothing
            }
        }

        /// If token is not valid or something went wrong during user creation, clear token and set status to done
        setIsAuth({
            ...isAuth,
            status: DataStatus.DONE,
        });
        localStorage.clear();
    }

    async function refreshAccount() {
        let account = await accountService.account();
        setIsAuth({
            ...isAuth,
            user: account.user,
        });
    }

    async function getAccount() {
        return await accountService.account();
    }

    async function authenticate(token) {
        // Store token
        localStorage.setItem('token', token);

        // Process token
        let success = await processToken(token);

        // Navigate to page initially requested by user or the home page if no page was requested
        if (success) {
            if (location.state && location.state.redirect) {
                navigate(location.state.redirect);
            } else {
                navigate('/');
            }
        }
        return success;
    }

    function clearAuthentication(autoLogout = false) {
        // Clear entire localstorage
        localStorage.clear();

        // Clear state
        setIsAuth({
            ...isAuth,
            isAuthenticated: false,
            user: null,
            roles: [],
        });

        // Navigate to login page and pass current page as redirect if user is logged out automatically
        if (autoLogout && location.pathname !== '/login') {
            navigate('/login', {state: {redirect: location.pathname}});
        } else {
            navigate('/login');
        }
    }

    function validateToken(token = null) {
        // Get token from localstorage if no token was passed
        if (!token) {
            token = localStorage.getItem('token');
        }

        // Validate token
        return checkIfTokenIsValid(token);
    }

    const contextData = {
        ...isAuth,
        authenticate: authenticate,
        clearAuthentication: clearAuthentication,
        refreshAccount: refreshAccount,
        validateToken: validateToken,
        isDeveloper: isAuth.roles.includes("Developer"),
        isAdmin: isAuth.roles.includes("Admin")
    };

    return (
        <AuthContext.Provider value={contextData}>
            {isAuth.status === DataStatus.DONE ? children : <Loading/>}
        </AuthContext.Provider>
    )
}

export default AuthContextProvider;
