import React from 'react';

function RegularButton(
    {
        title,
        type = "button",
        onClick,
        disabled,
        className,
        children
    }
) {
    return (
        <button title={title} type={type} onClick={onClick} disabled={disabled} className={className}>
            {children}
        </button>
    )
}

export default RegularButton
