import {ProblemIssueOrderBy} from "../../../../enums/problem-issue-order-by.enum";

export default class ProblemIssueOrderParams {
    orderBy;
    reverse;

    constructor(orderBy = ProblemIssueOrderBy.UpdatedOn, reverse = true) {
        if (orderBy && Object.keys(ProblemIssueOrderBy).includes(orderBy)) {
            this.orderBy = orderBy;
        } else {
            this.orderBy = ProblemIssueOrderBy.UpdatedOn;
        }

        if (reverse === true || reverse === false) {
            this.reverse = reverse;
        } else {
            this.reverse = true;
        }
    }
}
