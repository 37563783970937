import React, {createContext, useState} from 'react';
import Success from "../../components/Success/Success";

export const SuccessContext = createContext({});

function SuccessContextProvider({children}) {
    const [successData, setSuccessData] = useState(null);

    function showSuccess(description = null, duration = 5000, title = "Succes!") {
        setSuccessData({
            title,
            description,
            duration
        });
    }

    const contextData = {
        showSuccess: showSuccess,
    };

    return (
        <SuccessContext.Provider value={contextData}>
            <>
                {children}

                <Success successData={[successData, setSuccessData]}/>
            </>
        </SuccessContext.Provider>
    )
}

export default SuccessContextProvider;
