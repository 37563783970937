import Button from "../../Button/Button";
import Style from "../../../shared/data/models/style.model";
import {Theme} from "../../../shared/enums/theme.enum";
import {Appearance} from "../../../shared/enums/appearance.enum";
import ConfirmPopup from "../../Popup/ConfirmPopup/ConfirmPopup";
import React, {useContext, useState} from "react";
import {AuthContext} from "../../../shared/context/AuthContext";
import {UserService} from "../../../shared/services/http-services/user.service";
import ErrorsPopup from "../../Errors/ErrorsPopup/ErrorsPopup";
import parseException from "../../../shared/util/parse-exception";
import {SuccessContext} from "../../../shared/context/SuccessContext";
import User from "../../User/User";

function ApproveUser(
    {
        user,
        className,
        reloadData
    }
) {
    const {showSuccess} = useContext(SuccessContext);
    const authContext = useContext(AuthContext);
    const userService = new UserService(authContext);

    const [errors, setErrors] = useState([]);
    const [showConfirm, setShowConfirm] = useState(false);

    async function updateApproval() {
        try {
            let response = await userService.toggleApproval(user.id);

            if (response) {
                reloadData();
                showSuccess(`Gebruiker '${user.id}' is ${user.isApproved ? "afgekeurd" : "goedgekeurd"}!`);
            }
        } catch (e) {
            parseException(e, setErrors);
        }
    }

    return (
        <>
            <div className={className + " approve-users-button"}>
                {
                    user.id ? (
                        <>
                            {
                                user.isApproved ? (
                                    <Button style={new Style(Theme.DANGER, Appearance.REGULAR)} args={{
                                        onClick: () => {
                                            setShowConfirm(true)
                                        }
                                    }}>Afkeuren</Button>

                                ) : (
                                    <Button style={new Style(Theme.SUCCESS, Appearance.REGULAR)} args={{
                                        onClick: () => {
                                            setShowConfirm(true)
                                        }
                                    }}>Goedkeuren</Button>

                                )
                            }
                        </>
                    ) : null
                }
            </div>

            <div className={className}>
                {user.id}
            </div>

            <div className={className}>
                <User user={user}
                      newTab={true}/>
            </div>

            <div className={className}>
                {user.firstName}
            </div>

            <div className={className}>
                {user.lastName}
            </div>

            <div className={className}>
                {user.email}
            </div>

            <ConfirmPopup visible={[showConfirm, setShowConfirm]}
                          onConfirm={updateApproval}
                          message={
                              `Weet u zeker dat u de gebruiker '${user.id}' wilt ${user.isApproved ? "afkeuren" : "goedkeuren"}?`
                          }/>

            <ErrorsPopup header={`Kan de gebruiker '${user.id}' niet ${user.isApproved ? "afkeuren" : "goedkeuren"}.`}
                         errors={[errors, setErrors]}/>
        </>
    );
}

export default ApproveUser;
