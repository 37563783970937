import React from 'react';
import "./InfoSection.css";

function InfoSection(
    {
        label,
        children
    }
) {
    return (
        <section className="info-section">

            {
                label ? (
                    <header className="info-label">
                        {label}
                    </header>
                ) : null
            }
            <main>
                {children}
            </main>
        </section>
    );
}

export default InfoSection;
