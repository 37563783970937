import React from 'react';
import SelectInput from "./SelectInput/SelectInput";
import RegularInput from "./RegularInput/RegularInput";
import NumberInput from "./NumberInput/NumberInput";
import DataListInput from "./DataListInput/DataListInput";
import Spinner from "../../Spinner/Spinner";
import TextAreaInput from "./TextAreaInput/TextAreaInput";
import UserInput from "./UserInput/UserInput";
import {IconType} from "../../../shared/enums/icon-type.enum";
import Button from "../../Button/Button";
import Style from "../../../shared/data/models/style.model";
import {Theme} from "../../../shared/enums/theme.enum";
import {Appearance} from "../../../shared/enums/appearance.enum";

function Input(
    {
        nameAttribute,
        idAttribute = nameAttribute + "-field",
        inputType = "text",
        labelText,
        placeHolder,
        loading = false,
        disabled,
        state,
        className = "",
        options,
        onClick,
        buttonIcon = IconType.ARROW_ROTATE_RIGHT,
        buttonTitle = "",
        forceSetOption = false,
        required = false,
        maxlength = null,
        pattern = null,
        patternMessage = null,
        min = null,
        max = null,
        step = null,
        maxDigits = null,
        maxDecimals = null,
        initialRows = 2,
        maxRows = 5,
    }
) {
    let input = null;
    if (inputType === "user") {
        return (
            <UserInput
                nameAttribute={nameAttribute}
                disabled={disabled}
                state={state}
                labelText={labelText}
                placeHolder={placeHolder}
                required={required}
                maxlength={maxlength}
                pattern={pattern}
                patternMessage={patternMessage}
            />
        );
    } else if (inputType === "datalist") {
        input = (
            <DataListInput
                className="form-control"
                nameAttribute={nameAttribute}
                idAttribute={idAttribute}
                disabled={disabled}
                state={state}
                options={options}
                forceSetOption={forceSetOption}
                required={required}
                maxlength={maxlength}
                pattern={pattern}
                patternMessage={patternMessage}/>
        );
    } else if (inputType === "select") {
        input = (
            <SelectInput
                className="form-control"
                nameAttribute={nameAttribute}
                idAttribute={idAttribute}
                disabled={disabled}
                state={state}
                options={options}
                required={required}/>
        );
    } else if (inputType === "textarea") {
        input = (
            <TextAreaInput
                className="form-control"
                nameAttribute={nameAttribute}
                idAttribute={idAttribute}
                placeHolder={placeHolder}
                disabled={disabled}
                state={state}
                required={required}
                maxlength={maxlength}
                initialRows={initialRows}
                maxRows={maxRows}/>
        );
    } else if (inputType === "number") {
        input = (
            <NumberInput
                className="form-control"
                nameAttribute={nameAttribute}
                idAttribute={idAttribute}
                placeHolder={placeHolder}
                inputType={inputType}
                disabled={disabled}
                state={state}
                required={required}
                min={min}
                max={max}
                step={step}
                maxDigits={maxDigits}
                maxDecimals={maxDecimals}/>
        );
    } else {
        input = (
            <RegularInput
                className="form-control"
                nameAttribute={nameAttribute}
                idAttribute={idAttribute}
                placeHolder={placeHolder}
                inputType={inputType}
                disabled={disabled}
                state={state}
                required={required}
                maxlength={maxlength}
                pattern={pattern}
                patternMessage={patternMessage}/>
        );
    }

    if (required) {
        labelText = (
            <>
                {
                    labelText ? (
                        <>
                            {labelText}:
                        </>
                    ) : null
                }
                &nbsp;<span className="form-control-required">*</span>
            </>
        );
    } else if (labelText) {
        labelText = labelText + ":";
    }

    return (
        <div className={"form-group " + className}
             onClick={onClick}>
            <div className={"form-control-header " + (onClick ? "has-button" : "")}>
                {
                    labelText ? (
                        <label htmlFor={idAttribute}>
                            {labelText}
                        </label>
                    ) : null
                }

                {
                    onClick ? (
                        <Button title={buttonTitle} icon={buttonIcon}
                                style={new Style(Theme.DARK, Appearance.MINIMAL)} args={{
                            onClick: onClick,
                            disabled: !disabled
                        }}/>
                    ) : null
                }
            </div>

            {input}

            {loading ? <Spinner absolute={true} showOverlay={true}/> : null}
        </div>
    );
}

export default Input
