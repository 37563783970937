import {HttpService} from "./http.service";
import {HttpMethod} from "../../enums/http-method.enum";
import ProblemIssueSerializer from "../../data/serializers/problem-issue.serializer";
import ProblemIssuePaginationInfoSerializer from "../../data/serializers/problem-issue-pagination-info.serializer";
import {ProblemIssueSearchType} from "../../enums/problem-issue-search-type.enum";
import {QuickFilterType} from "../../enums/quick-filter-type.enum";
import ProblemIssueQuickFilterParams
    from "../../data/models/problem-issue/params/problem-issue-quick-filter-params-model";
import ActivitySerializer from "../../data/serializers/activity.serializer";

export class ProblemIssueService extends HttpService {

    constructor(authContext) {
        super(authContext, "ProblemIssues", new ProblemIssueSerializer());

        this.paginationInfoSerializer = new ProblemIssuePaginationInfoSerializer();
    }

    async getIssue(id) {
        const response = await this.request(id, HttpMethod.GET);
        if (response) {
            try {
                return this.serializer.fromJson(response.data);
            } catch (e) {
                // Do nothing
            }
        }

        return {};
    }

    async createIssue(issueParams) {
        const response = await this.request("", HttpMethod.POST, issueParams);
        if (response) {
            try {
                return this.serializer.fromJson(response.data);
            } catch (e) {
                // Do nothing
            }
        }

        return {};
    }

    async updateIssue(id, issueParams) {
        const response = await this.request(id, HttpMethod.PUT, issueParams);
        return !!response;
    }

    async deleteIssue(id) {
        const response = await this.request(id, HttpMethod.DELETE);
        return !!response;
    }

    async addComment(id, comment) {
        const data = new ActivitySerializer().toJson(comment);

        const response = await this.request(`${id}/Comments/Add`, HttpMethod.POST, data);
        return !!response;
    }

    async deleteComment(id, comment) {
        const data = new ActivitySerializer().toJson(comment);

        const response = await this.request(`${id}/Comments/Delete`, HttpMethod.DELETE, data);
        return !!response;
    }

    async getIssues(orderParams = {}, paginationParams = {}, searchParams = {}, quickFilter = {}) {
        let data = {
            ...orderParams,
            ...paginationParams
        }

        // Add search params
        let searchType = ProblemIssueSearchType.NONE;
        if (searchParams && searchParams.type && searchParams.type !== ProblemIssueSearchType.NONE
            && searchParams.params && Object.keys(searchParams.params).length > 0) {
            data = {
                ...data,
                ...searchParams.params
            };

            searchType = searchParams.type;
        }

        // Add quick filter params
        const quickFilterParams = new ProblemIssueQuickFilterParams(quickFilter);
        if (quickFilterParams && quickFilterParams.quickFilter && quickFilterParams.quickFilter !== QuickFilterType.ALL) {
            data = {
                ...data,
                ...quickFilterParams
            };
        }

        return await this.#getIssues(data, searchType);
    }

    async #getIssues(data, searchType) {
        if (!data) {
            return;
        }

        const response = await this.#requestIssues(data, searchType);
        if (response) {
            try {

                return [this.serializer.fromJsonArray(response.data), this.#getPaginationInfo(response)];
            } catch (e) {
                // Do nothing
            }
        }

        return [[], {}];
    }

    async #requestIssues(data, searchType) {
        switch (searchType) {
            case ProblemIssueSearchType.BASIC:
                return await this.request("Search/Basic", HttpMethod.GET, data);
            case ProblemIssueSearchType.ADVANCED:
                return await this.request("Search/Advanced", HttpMethod.GET, data);
            case ProblemIssueSearchType.NONE:
            default:
                return await this.request("", HttpMethod.GET, data);
        }
    }

    #getPaginationInfo(response) {
        if (response && response.headers && response.headers["x-pagination"]) {
            try {
                let paginationInfoHeader = response.headers["x-pagination"];
                let paginationInfoJson = JSON.parse(paginationInfoHeader);
                return this.paginationInfoSerializer.fromJson(paginationInfoJson);
            } catch (e) {
                // Do nothing
            }
        }

        return {};
    }
}
