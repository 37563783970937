import {QuickFilterType} from "../../../../enums/quick-filter-type.enum";
import QuickFilter from "../../quick-filter.model";

export default class ProblemIssueQuickFilterParams {
    quickFilter;
    userId;

    constructor(quickFilter = new QuickFilter()) {
        this.quickFilter = quickFilter.type;

        if (quickFilter.userId !== null
            && (quickFilter.type === QuickFilterType.ASSIGNEDTO ||
                quickFilter.type === QuickFilterType.REPORTEDBY ||
                quickFilter.type === QuickFilterType.RESOLVEDBY)) {
            this.userId = quickFilter.userId;
        }
    }
}
