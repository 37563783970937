import React from "react";
import ApproveUsers from "../../components/ApproveUsers/ApproveUsers";
import AuthenticatedLayout from "../../components/Layout/AuthenticatedLayout/AuthenticatedLayout";

function Users() {
    return (
        <AuthenticatedLayout title={"Overzicht gebruikers"} backTo="/">
            <ApproveUsers/>
        </AuthenticatedLayout>
    );
}

export default Users;
