import React from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import {ProblemIssuePaginationSize} from "../../../../shared/enums/problem-issue-pagination-size.enum";
import "./ProblemIssue.css";
import Card from "../../../Card/Card";
import Priority from "../../Priority/Priority";
import Pill from "../../../Pill/Pill";
import Style from "../../../../shared/data/models/style.model";
import {Theme} from "../../../../shared/enums/theme.enum";
import {Appearance} from "../../../../shared/enums/appearance.enum";
import {IconType} from "../../../../shared/enums/icon-type.enum";
import Status from "../../Status/Status";
import User from "../../../User/User";
import {ActivityType} from "../../../../shared/enums/activity-type.enum";

function ProblemIssue(
    {
        issue,
        issueIndex,
        setSelectedIssue,
        setSize
    }
) {
    const location = useLocation();
    const navigate = useNavigate();

    function onClick() {
        setSelectedIssue(issue.id);

        // Determine current batch + add 1 batch size, to make sure selected issue can always be shown in the middle of page
        setSize(Math.ceil((issueIndex + 1) / ProblemIssuePaginationSize.REGULAR) * ProblemIssuePaginationSize.REGULAR + ProblemIssuePaginationSize.REGULAR);

        // Navigate to details page
        navigate(`/issue/details/${issue.id}`, {state: {from: location.pathname}});
    }

    return (
        <Card id={issue.id} className="overview-item" onClick={onClick}>
            <header>
                <h2>{issue.title}</h2>
                <div title="Laatste wijziging" className="last-updated">
                    {issue.mutatedOn.hasDate() ? issue.mutatedOn.readableDate() : issue.openedOn.readableDate()}
                </div>
            </header>
            <main>
                <div className="description">
                    {issue.description}
                </div>
            </main>
            <footer>
                <div className="pills">
                    <Status status={issue.status}/>
                    <Priority priority={issue.priority}/>
                    <Pill style={new Style(Theme.DARK, Appearance.OUTLINE)}>
                        #{issue.id}
                    </Pill>
                    <Pill icon={IconType.MESSAGE} style={new Style(Theme.DARK, Appearance.MINIMAL)}>
                        {issue.activities.filter(activity => activity.type === ActivityType.COMMENT).length}
                    </Pill>
                </div>
                {
                    issue.allocatedTo ?
                        (
                            <div className="user">
                                Toegewezen aan:&nbsp;
                                <User user={issue.allocatedTo} showLink={false}/>
                            </div>
                        ) : null
                }
            </footer>

        </Card>
    )
}

export default ProblemIssue
