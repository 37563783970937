import React from 'react';
import {Link} from "react-router-dom";

function LinkButton(
    {
        title,
        to,
        state,
        className,
        children
    }
) {
    return (
        <Link title={title} to={to} className={className} state={state}>
            {children}
        </Link>
    )
}

export default LinkButton
