import {QuickFilterType} from "../../enums/quick-filter-type.enum";

export default class QuickFilter {
    type;
    userId;

    constructor(type = QuickFilterType.ALL, userId = null) {
        this.type = type;
        this.userId = userId;
    }
}
