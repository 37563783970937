import Errors from "../Errors";
import Popup from "../../Popup/Popup";

function ErrorsPopup(
    {
        title = "Let op!",
        header = null,
        errors: [errors, setErrors],
    }
) {
    function close() {
        setErrors([]);
    }

    if (!errors || errors.length === 0) {
        return null;
    }

    return (
        <Popup title={title}
               closePopup={close}>
            <Errors header={<h3>{header}</h3>}
                    errors={errors}/>
        </Popup>
    );
}

export default ErrorsPopup;
