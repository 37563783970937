import {DataStatus} from "../../../../shared/enums/data-status.enum";
import Input from "../../../Form/Input/Input";
import React, {useEffect, useState} from "react";
import {arrayValuesValid} from "../../../../shared/util/array";

function ProblemIssueApiValue(
    {
        name,
        label,
        disabled = true,
        skipNextReset = false,
        required = false,
        apiValues,
        state: [state, stateSetter],
        deps = [],
    }
) {
    const [skipResetState, setSkipResetState] = useState(skipNextReset);
    useEffect(() => {
        if (skipNextReset) {
            setSkipResetState(true);
        }
    }, [skipNextReset]);

    useEffect(() => {
        execute();
    }, deps);

    async function execute() {
        // Skip reset of state once when load has been disabled previously.
        if (!skipResetState) {
            stateSetter("");
        }

        // Check if all dependencies are valid.
        if (!arrayValuesValid(deps)) {
            return;
        }

        // Execute the api call.
        await apiValues.execute(deps);

        // Enable reset of state again.
        if (skipResetState) {
            setSkipResetState(false);
        }
    }

    return (
        <div>
            <Input nameAttribute={name}
                   inputType="datalist"
                   labelText={label}
                   disabled={disabled || apiValues.status !== DataStatus.DONE || apiValues.state.length === 0 || !arrayValuesValid(deps)}
                   loading={apiValues.status === DataStatus.PENDING}
                   state={[state, stateSetter]}
                   required={required}
                   options={
                       apiValues.state.map(descriptor => {
                           return {value: descriptor.id, label: descriptor.description}
                       })
                   }
                   className={apiValues.status === DataStatus.ERROR ? "has-error" : ""}
                   onClick={apiValues.status === DataStatus.ERROR ? async () => {
                       await execute();
                   } : null}
                   buttonTitle="Opnieuw proberen"/>
        </div>
    )
}

export default ProblemIssueApiValue;
