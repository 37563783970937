import React from 'react';
import './Spinner.css';

function Spinner(
    {
        absolute = false,
        showOverlay = false
    }
) {
    return (
        <div
            className={"spinnerWrapper " + (absolute ? "spinner-absolute " : "") + (showOverlay ? "spinner-overlay " : "")}>
            <div className="spinner">
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                    <title>loading</title>
                    <path fill="#FFFFFF"
                          d="M14.501 7.499c-0.276 0-0.5 0.224-0.5 0.5 0 3.309-2.691 6-6 6-1.68 0-3.254-0.706-4.38-1.913l1.232-1.232c0.143-0.143 0.186-0.358 0.108-0.545s-0.259-0.309-0.461-0.309h-3c-0.276 0-0.5 0.224-0.5 0.5v3c0 0.202 0.122 0.385 0.309 0.462l0.191 0.038c0.13 0 0.258-0.051 0.354-0.146l1.062-1.062c1.312 1.393 3.137 2.208 5.085 2.208 3.859 0 7-3.141 7-7 0-0.277-0.224-0.501-0.5-0.501zM1.501 8.501c0.276 0 0.5-0.224 0.5-0.5 0-3.309 2.691-6 6-6 1.679 0 3.253 0.705 4.379 1.912l-1.234 1.234c-0.143 0.143-0.186 0.358-0.108 0.545s0.26 0.308 0.462 0.308h3c0.276 0 0.5-0.224 0.5-0.5v-3c0-0.202-0.122-0.385-0.309-0.462-0.186-0.076-0.402-0.034-0.545 0.108l-1.061 1.061c-1.313-1.393-3.137-2.206-5.085-2.206-3.859 0-7 3.141-7 7 0.001 0.276 0.225 0.5 0.501 0.5z"></path>
                </svg>
            </div>
        </div>
    );
}

export default Spinner
