import Card from "../Card/Card";
import "./Errors.css";

function Errors(
    {
        header = null,
        errors = []
    }
) {
    if (errors.length === 0) {
        return null;
    }

    return (
        <Card className="errors-card">
            {
                header ?
                    (
                        <header>
                            {header}
                        </header>
                    ) : null
            }

            <main className={(header ? "has-header" : "")}>
                {
                    errors.length === 1 ?
                        (
                            <p>
                                {errors[0]}
                            </p>
                        ) : (
                            <>
                                <p>
                                    Er zijn {errors.length} fouten opgetreden:
                                </p>
                                <ul>
                                    {
                                        errors.map((error, index) => {
                                            return <li key={index}>{error}</li>
                                        })
                                    }
                                </ul>
                            </>
                        )
                }
            </main>
        </Card>

    );
}

export default Errors;
