import ProblemIssuePaginationInfo from "../models/problem-issue/problem-issue-pagination-info.model";

export default class ProblemIssuePaginationInfoSerializer {
    fromJson(json) {
        const paginationInfo = new ProblemIssuePaginationInfo();
        paginationInfo.hasNext = json.HasNext;
        paginationInfo.afterCursor = json.AfterCursor;
        paginationInfo.resultsReturned = json.ResultsReturned;

        return paginationInfo;
    }

    toJson(paginationInfo) {
        return {
            HasNext: paginationInfo.hasNext,
            AfterCursor: paginationInfo.afterCursor,
            ResultsReturned: paginationInfo.resultsReturned
        };
    }
}
