import {useEffect, useRef} from "react";
import "./CountDown.css";

function CountDown(
    {
        duration = 0,
        isRunning = false,
        children
    }
) {
    const circle = useRef(null);

    useEffect(() => {
        if (duration <= 0) {
            return;
        }

        // Set up animation
        if (circle.current) {
            circle.current.style.animationDuration = `${duration}ms`;
        }

    }, [duration]);

    useEffect(() => {
        if (!isRunning) {
            // Clear animation
            if (circle.current) {
                circle.current.style.animationDuration = `0ms`;
            }
        }
    }, [isRunning]);

    if (!isRunning) {
        return null;
    }

    return (
        <div className="countdown">
            <div className="countdown-timer">
                <svg viewBox="0 0 100 100">
                    <circle ref={circle} cx="50" cy="50" r="43"/>
                </svg>
            </div>

            <div className="countdown-content">
                {children}
            </div>
        </div>
    );
}

export default CountDown;
