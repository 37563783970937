import React, {useContext, useEffect, useState} from "react";
import ActivityComponent from "./Activity/Activity";
import "./Activities.css";
import {ActivityType, ActivityTypeLabel} from "../../shared/enums/activity-type.enum";
import Filter from "../Filter/Filter";
import Form from "../Form/Form";
import Input from "../Form/Input/Input";
import {DescriptionType, DescriptionTypeLabel} from "../../shared/enums/description-type.enum";
import {Position} from "../../shared/enums/position.enum";
import {useMediaQuery} from "../../shared/util/custom-hooks/use-media-query";
import Card from "../Card/Card";
import {AuthContext} from "../../shared/context/AuthContext";
import {ProblemIssueService} from "../../shared/services/http-services/problem-issue.service";
import Activity from "../../shared/data/models/activity.model";
import ErrorsPopup from "../Errors/ErrorsPopup/ErrorsPopup";
import parseException from "../../shared/util/parse-exception";
import {SuccessContext} from "../../shared/context/SuccessContext";

function Activities(
    {
        label,
        activities,
        issueId,
        reload
    }
) {
    const {showSuccess} = useContext(SuccessContext);
    const authContext = useContext(AuthContext);
    const problemIssueService = new ProblemIssueService(authContext);

    const smallerScreen = useMediaQuery("(max-width: 499px)");
    const smallScreen = useMediaQuery("(max-width: 767px)");

    const [errors, setErrors] = useState([]);

    const [comment, setComment] = useState("");
    const [commentOrigin, setCommentOrigin] = useState(DescriptionType.REGULAR);

    const [activityType, setActivityType] = useState(ActivityType.COMMENT);
    const [filteredActivities, setFilteredActivities] = useState(activities.filter(activity => activityType === ActivityType.ALL || activity.type === activityType));

    const activityTypes = Object.keys(ActivityType).map((key) => {
        return {
            value: key, label: ActivityTypeLabel(key)
        }
    });

    const descriptionTypes = Object.keys(DescriptionType).map((key) => {
        return {
            value: key, label: DescriptionTypeLabel(key)
        }
    });

    useEffect(() => {
        setFilteredActivities(activities.filter(activity => activityType === ActivityType.ALL || activity.type === activityType));
    }, [activityType]);

    async function addComment() {
        const newComment = new Activity();
        newComment.message = comment;
        newComment.origin = commentOrigin;
        newComment.type = ActivityType.COMMENT;

        try {
            const response = await problemIssueService.addComment(issueId, newComment);

            if (response) {
                setComment("");
                setCommentOrigin(DescriptionType.REGULAR);
                await reload();

                showSuccess("Opmerking is geplaatst!", 2000);
            }
        } catch (e) {
            parseException(e, setErrors);
        }

    }

    return (
        <section>
            {
                label ? (
                    <header className="activity-label">
                        {label}
                    </header>
                ) : null
            }
            <main>
                <Filter selectedFilter={[activityType, setActivityType]}
                        filters={activityTypes}/>

                {
                    filteredActivities.length > 0 ?
                        <>
                            <div className="activities">
                                {
                                    filteredActivities.map(activity => (
                                        <ActivityComponent
                                            key={activity.id}
                                            activity={activity}
                                            issueId={issueId}
                                            reload={reload}/>
                                    ))
                                }
                            </div>
                        </>
                        :
                        <p>
                            <i>Geen {activityType !== ActivityType.ALL ? ActivityTypeLabel(activityType).toLowerCase() : "activiteiten"} gevonden</i>
                        </p>
                }

                <Card>
                    <Form
                        buttonText="Plaatsen"
                        useLoader={true}
                        handleSubmit={addComment}
                        inputs={
                            [
                                <Input key="comment"
                                       nameAttribute="comment"
                                       inputType="textarea"
                                       labelText="Plaats een opmerking"
                                       state={[comment, setComment]}
                                       required={true}
                                       initialRows={3}
                                       maxRows={15}/>,
                                <>
                                    {
                                        smallerScreen ? (
                                            <Input key="origin"
                                                   nameAttribute="origin"
                                                   inputType="select"
                                                   labelText="Locatie"
                                                   state={[commentOrigin, setCommentOrigin]}
                                                   options={descriptionTypes}/>
                                        ) : null
                                    }
                                </>
                            ]
                        }
                        extraButtonsPosition={Position.LEFT}
                        extraButtons={
                            smallerScreen ? null : (
                                <Input nameAttribute="origin"
                                       inputType="select"
                                       labelText={!smallScreen ? "Locatie" : null}
                                       className="no-margin form-group-inline "
                                       state={[commentOrigin, setCommentOrigin]}
                                       options={descriptionTypes}/>
                            )
                        }/>
                </Card>
            </main>

            <ErrorsPopup header="Kan opmerking niet opslaan."
                         errors={[errors, setErrors]}/>
        </section>
    )
}

export default Activities
