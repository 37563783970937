function Menu
(
    {
        menuItems
    }
) {
    return (
        <ul className="main-menu">
            {menuItems}
        </ul>
    );
}

export default Menu;
