import React from "react";

function RegularInput(
    {
        className,
        nameAttribute,
        idAttribute,
        placeHolder,
        inputType,
        disabled,
        state: [stateValue, stateSetter],
        required = false,
        maxlength = null,
        pattern = null,
        patternMessage = null,
    }
) {
    return (
        <input
            className={className}
            type={inputType}
            name={nameAttribute}
            value={stateValue}
            placeholder={placeHolder}
            disabled={disabled}
            onChange={(e) => stateSetter(e.target.value)}
            id={idAttribute}
            required={required}
            maxLength={maxlength}
            pattern={pattern}
            title={patternMessage}/>
    )
}

export default RegularInput
