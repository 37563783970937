import Loading from "../Loading/Loading";
import {useContext, useEffect} from "react";
import {AuthContext} from "../../shared/context/AuthContext";

function Logout() {
    const {clearAuthentication} = useContext(AuthContext);

    useEffect(() => {
        clearAuthentication();
    }, []);

    return <Loading/>;
}

export default Logout;
