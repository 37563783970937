import Input from "../Form/Input/Input";
import Style from "../../shared/data/models/style.model";
import {Theme} from "../../shared/enums/theme.enum";
import {Appearance} from "../../shared/enums/appearance.enum";
import {useMediaQuery} from "../../shared/util/custom-hooks/use-media-query";
import Button from "../Button/Button";
import React from "react";
import "./Filter.css";


function Filter(
    {
        selectedFilter: [selectedFilter, setSelectedFilter],
        filters = [],
        className,
        smallScreenSize = 499,

        label,
        disabled = false,
        children
    }
) {
    const smallScreen = useMediaQuery(`(max-width: ${smallScreenSize}px)`);

    return (
        <div className={"filter-container " + className}>
            {
                smallScreen ? (
                    <Input nameAttribute="filter"
                           inputType="select"
                           labelText={label}
                           disabled={disabled}
                           state={[selectedFilter, setSelectedFilter]}
                           className="form-group-inline"
                           options={filters}/>
                ) : (
                    children ? children : (
                        filters.map(
                            filter => (
                                <Button
                                    key={filter.value}
                                    args={{
                                        onClick: () => {
                                            setSelectedFilter(filter.value)
                                        }
                                    }}
                                    style={filter.value === selectedFilter ? new Style(Theme.DARK, Appearance.REGULAR) : new Style(Theme.DARK, Appearance.OUTLINE)}>
                                    {filter.label}
                                </Button>
                            )
                        )
                    )
                )
            }
        </div>
    );
}

export default Filter;
