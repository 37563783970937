import {InvalidDescriptionTypeException} from "../exceptions/invalid-description-type.exception";

export const DescriptionType = {
    REGULAR: "REGULAR",
    SOLUTION: "SOLUTION",
    SITE_COMMUNICATION: "SITE_COMMUNICATION",
}

export function DescriptionTypeLabel(descriptionType) {
    switch (descriptionType) {
        case DescriptionType.REGULAR:
            return "Omschrijving";
        case DescriptionType.SOLUTION:
            return "Oplossing";
        case DescriptionType.SITE_COMMUNICATION:
            return "Webcommunicatie";
        default:
            throw new InvalidDescriptionTypeException(descriptionType);
    }
}
