import UserSerializer from "./user/user.serializer";
import Account from "../models/account.model";

export default class AccountSerializer {
    fromJson(json) {
        const account = new Account();
        account.user = new UserSerializer().fromJson(json.user);
        account.roles = json.roles;

        return account;
    }

    toJson(account) {
        return {
            user: new UserSerializer().toJson(account.user),
            roles: account.roles
        };
    }
}
