// Copied from: https://stackoverflow.com/a/16436975
function arraysEqual(a, b) {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;

    for (let i = 0; i < a.length; ++i) {
        if (a[i] !== b[i]) return false;
    }
    return true;
}

function arrayValuesValid(array) {
    for (let i = 0; i < array.length; i++) {
        if (!array[i]) {
            return false;
        }
    }

    return true;
}

export {arraysEqual, arrayValuesValid};
