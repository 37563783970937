import Icon from "../Icon/Icon";
import Style from "../../shared/data/models/style.model";
import "./Pill.css";

function Pill(
    {
        icon,
        style = new Style(),
        bold = false,
        children,
    }
) {
    const className = style.getClassName("pill") + (bold ? " pill-bold" : "")

    return (
        <div className={className}>
            <Icon icon={icon}/> {children}
        </div>
    )
}

export default Pill;
