import React, {useContext, useState} from 'react';
import UnauthenticatedLayout from "../../components/Layout/UnauthenticatedLayout/UnauthenticatedLayout";
import Form from "../../components/Form/Form";
import Input from "../../components/Form/Input/Input";
import {AuthContext} from "../../shared/context/AuthContext";
import {UserService} from "../../shared/services/http-services/user.service";
import {Link, useNavigate} from "react-router-dom";

function Register() {
    const navigate = useNavigate();
    const authContext = useContext(AuthContext);

    const userService = new UserService(authContext);

    const [username, setUsername] = useState();
    const [password, setPassword] = useState();
    const [confirmPassword, setConfirmPassword] = useState();

    async function register() {
        const success = await userService.register(username, password, confirmPassword);
        if (success) {
            navigate("/register/success");
        }
    }

    return (
        <UnauthenticatedLayout title="Registreren">
            <Form handleSubmit={register} useLoader={true} inputs={
                [
                    <Input key="username"
                           nameAttribute="username"
                           labelText="Shortname"
                           state={[username, setUsername]}
                           required={true}
                           maxlength={4}/>,
                    <Input key="password"
                           nameAttribute="password"
                           inputType="password"
                           labelText="Wachtwoord"
                           state={[password, setPassword]}
                           required={true}
                           maxlength={30}/>,
                    <Input key="confirm-password"
                           nameAttribute="confirm-password"
                           inputType="password"
                           labelText="Wachtwoord bevestigen"
                           state={[confirmPassword, setConfirmPassword]}
                           required={true}
                           maxlength={30}/>
                ]
            } info={
                <p>
                    Al een account? <Link to="/login">Log in</Link>!
                </p>
            }/>
        </UnauthenticatedLayout>
    );
}

export default Register
